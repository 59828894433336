import { mapMutations, mapState } from "vuex"
/** CONSTANTS */
import { API_BASE_URL } from "../../../../constants/config"
import { IMG_LOGO, IMG_FACEBOOK, IMG_GOOGLE } from "../../../../constants/media"
import { rules } from "../../../../utils/validate"
import { genders, graduations } from "../../../../constants/selections"

/** FUNCTIONS */
import { axiosPost } from "../../../../utils/api"
import { API_PROFILE, API_STUDENT_UPDATE, API_TEACHER_UPDATE } from "../../../../constants/endpoints"
import { confirmAlert, errorAlert, successAlert } from "../../../../utils/alert"
import { createBase64 } from "../../../../utils/app"
import { filterDateToString } from "../../../../utils/filters"

/** COMPONENTS */
import InputGroup from "../../../../components/InputGroup.vue"
import SplitDateSelect from "../../../../components/SplitDateSelect.vue"
import SelectMd from "../../../../components/SelectMd.vue"
import RadioGroup from "../../../../components/RadioGroup.vue"
import BtnUpload from "../../../../components/buttons/BtnUpload.vue"
import BtnSm from "../../../../components/buttons/BtnSm.vue"
import { MSG_CHANGE_SUCCESS, MSG_CONFIRM_CHANGE, MSG_VALIDATE_FILE } from "../../../../constants/messages"
export default {
  data: () => ({
    apiURL: API_BASE_URL,
    imgLogo: IMG_LOGO,
    imgFacebook: IMG_FACEBOOK,
    imgGoogle: IMG_GOOGLE,
    filename: null,
    account: {},
    rules: {
      required: rules.required,
      email: rules.email,
      phone: rules.phone,
      match: rules.match,
      password: rules.password,
      idCard: rules.idCard
    },
    genders: genders,
    graduations: graduations,
    layout: {
      half: "col-12 col-sm-4 col-md-4 col-xl-3",
      full: "col-12 col-sm-8 col-md-8 col-xl-6"
    },
    isValid: false,
    isLoading: false,
    isStarter: false
  }),
  components: {
    InputGroup,
    SplitDateSelect,
    SelectMd,
    RadioGroup,
    BtnUpload,
    BtnSm
  },
  mounted() {
    this.fetchUser()
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    ...mapMutations({ setAuth: "SET_AUTH" }),
    async fetchUser() {
      const {data} = await axiosPost(API_PROFILE, { token: this.user.token, user_id: this.user.userId })
      const { user_id, username, firstname, lastname, type, sex, email, tel, university, education, faculty, date_of_birth, id_card, token } = data
      this.account = {
        user_id: user_id,
        username: username,
        firstname: firstname,
        lastname: lastname,
        date_of_birth: filterDateToString(date_of_birth),
        sex: sex == "ชาย" ? 1 : sex == "หญิง" ? 2 : 3,
        email: email,
        tel: tel,
        type: type,
        id_card: id_card, 
        university: university,
        education: type == "teacher" ? education : "",
        faculty: type == "student" ? faculty : "",
        file: "",
        file_type: "",
        token: token
      }
      this.isStarter = true
    },
    async submit() {
      const isValid = this.$refs.editForm.validate()
      if (isValid) {
        const isConfirm = await confirmAlert(MSG_CONFIRM_CHANGE + "ข้อมูลส่วนตัว?")
        if (isConfirm) {
          let endpoint = API_TEACHER_UPDATE
          if (this.account.type == "student") {
            endpoint = API_STUDENT_UPDATE
          }
          this.isLoading = true
          const { data, code, message } = await axiosPost(endpoint, this.account)
          if (code == 200) {
            this.setAuth(data)
            await successAlert(MSG_CHANGE_SUCCESS)
            location.reload()
          } else {
            await errorAlert(message)
          }
          this.isLoading = false
        }
      }
    },
    async onchangeHandler(file) {
      if (this.account.type == "teacher") {
        const imgExt = ["application/pdf"]
        if (file.type && imgExt.includes(file.type) && file.size < 5000000) {
          const result = await createBase64(file)
          this.account.file_type = "pdf"
          this.account.file = result.split(",")[1]
          this.filename = file.name
        } else {
          await errorAlert(MSG_VALIDATE_FILE)
        }
      }
    }
  }
}
