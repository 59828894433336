<template>
  <div>
    <input class="d-none" type="file" @change="onchangeHandler" ref="inputFile" :accept="extAccept" />
    <v-btn
      :class="`btn-${size} ${size !== 'sm' ? 'btn-upload' : ''} btn-${theme} btn-rounded-pill ${
        absolute ? 'btn-cover-upload' : ''
      }`"
      @click="openFileDialog()"
      :disabled="isDisabled"
      ><slot>อัปโหลด</slot></v-btn
    >
  </div>
</template>

<script>
export default {
  props: {
    extAccept: {
      type: String,
      default: () => ".jpg,.jpeg,.png,.pdf"
    },
    size: {
      type: String,
      default: () => "md"
    },
    theme: {
      type: String,
      default: () => "light"
    },
    absolute: {
      type: Boolean,
      default: () => false
    },
    isDisabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    openFileDialog() {
      this.$refs.inputFile.click()
    },
    onchangeHandler(e) {
      const { files } = e.target
      this.$emit("onchangeHandler", files[0])
    }
  }
}
</script>
