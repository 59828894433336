<template>
  <!-- :to="`/post/edit/${details.post_id}`" -->
  <v-card class="card-post mb-4">
    <v-row>
      <v-list-item class="profile-list d-flex">
        <Avatar
          :image="details.img"
          :size="50"
          @click.native="goPage(`/profile/timeline/${details.user_id}`)"
          color="white"
        />
        <v-list-item-content class="text-start" @click="goPage(`/profile/timeline/${details.user_id}`)">
          <a>
            <h5 class="text-name">{{ details.fullname }}</h5>
          </a>
          <p class="text-date">{{ details.create_at }}</p>
          <p class="text-privacy grey--text" v-if="details.type == 'course'">({{ privacy }})</p>
        </v-list-item-content>
        <slot name="tag">
          <TagSubject class="ml-auto" v-if="details.type == 'course'">{{ details.subject }}</TagSubject>
        </slot>
      </v-list-item>
      <v-list-item
        class="text-start"
        :ripple="false"
        @click="
          goPage(
            `/${details.type == 'course' ? 'course/view' : 'post'}/${details.post_id}/${
              $route.name == 'ViewCourseByGroup' || $route.name == 'TimelineGroup' ? `group/${details.group_id}` : ''
            }`
          )
        "
        >{{ details.type == "course" ? details.caption : details.title }}</v-list-item
      >
      <WrapperGallery
        :gallery="details.files"
        :images="images"
        v-if="details.type == 'course' || details.file_type == 'img'"
      />
      <WrapperFiles :files="details.files" v-if="details.file_type == 'pdf'" />
      <WrapperYoutube :link="details.link" v-if="!!details.link" />
      <v-flex xs12></v-flex>

      <v-list-item
        class="flex-action justify-space-between my-4 border-verticle"
        v-if="
          ($route.name !== 'timeline' && $route.name !== 'ViewCourse' && $route.name !== 'Feed') ||
          details.privacy !== 'group'
        "
      >
        <v-icon class="mr-2">mdi-chat-outline</v-icon>
        <v-list-item-title class="text-start">
          <small>
            <span class="text-comment">{{ details.comment_count }}</span>
          </small>
        </v-list-item-title>
        <v-icon class="mr-2" @click="likePost()" v-if="details.is_like" color="error">mdi-heart</v-icon>
        <v-icon class="mr-2" @click="likePost()" v-else>mdi-heart-outline</v-icon>
        <v-list-item-title class="text-start" @click="likePost()">
          <small>
            <span>{{ details.emoticon_count }}</span>
          </small>
        </v-list-item-title>
        <v-icon class="mr-2">mdi-share-variant</v-icon>
        <v-list-item-title class="text-start">
          <small>
            <span>แชร์</span>
          </small>
        </v-list-item-title>
        <v-icon class="mr-2" @click="favoritePost()" :color="details.is_favorite ? 'primary' : ''">mdi-bookmark</v-icon>
        <v-list-item-title class="text-start" @click="favoritePost()">
          <small>
            <span>{{ details.favorite_count }}</span>
          </small>
        </v-list-item-title>
        <v-menu offset-y left class="d-flex" v-if="user.userId == details.user_id">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on" class="d-flex grey--text">
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editPost()">
              <v-icon class="mr-2">mdi-pencil</v-icon>
              <a>
                <p>แก้ไข</p>
              </a>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="deletePost()">
              <v-icon class="mr-2">mdi-trash-can</v-icon>
              <a>
                <p class="mb-0">ลบ</p>
              </a>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>

      <v-list-item
        v-if="
          ($route.name !== 'timeline' &&
            $route.name !== 'ViewCourseByGroup' &&
            $route.name !== 'ViewCourse' &&
            $route.name !== 'Feed') ||
          details.privacy !== 'group'
        "
      >
        <Avatar
          :image="user.img"
          :size="45"
          color="white"
          removeMargin
          @click.native="goPage(`/profile/timeline/${user.userId}`)"
        />
        <v-list-item-content>
          <InputGroup
            class="input-comment w-100"
            placeholder="เขียนข้อความ"
            layout="col-auto"
            color="primary"
            @keydown.native="sendComment($event, details.post_id)"
            ref="inputComment"
          />
        </v-list-item-content>
      </v-list-item>

      <WrapperComment
        :postId="details.post_id"
        :comments="details.comments"
        :userId="details.user_id"
        @replySuccess="replySuccess"
        v-if="
          ($route.name !== 'timeline' &&
            $route.name !== 'ViewCourseByGroup' &&
            $route.name !== 'ViewCourse' &&
            $route.name !== 'Feed') ||
          details.privacy !== 'group'
        "
      />
      <BtnSm
        class="btn-primary btn-rounded-pill mx-auto my-4"
        v-if="
          ($route.name == 'timeline' || $route.name == 'ViewCourse' || $route.name == 'Feed') &&
          details.privacy == 'group'
        "
        @click.native="join()"
      >
        <v-icon v-if="!details.ismember">mdi-plus</v-icon>
        {{ details.ismember ? "กดเข้ากลุ่ม" : "ขอเข้าร่วมกลุ่ม" }}
      </BtnSm>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from "vuex"
import { ICON_HAPPY } from "../constants/media"
import { API_BASE_URL } from "../constants/config"
import { API_COMMENT_CREATE, API_GROUP_JOIN } from "../constants/endpoints"
import { MSG_CONFIRM_JOIN } from "../constants/messages"
import { goPage } from "../utils/app"
import { confirmAlert, errorAlert, successAlert } from "../utils/alert"
import { axiosPost } from "../utils/api"
import { like, favorite, deletePost } from "../services/post"

import Avatar from "./Avatar"
import BtnSm from "./buttons/BtnSm"
import InputGroup from "./InputGroup"
import TagSubject from "./TagSubject"
import WrapperGallery from "./wrapperMediaCard/WrapperGallery"
import WrapperFiles from "./wrapperMediaCard/WrapperFiles"
import WrapperYoutube from "./wrapperMediaCard/WrapperYoutube"
import WrapperComment from "./wrapperMediaCard/WrapperComment"
export default {
  data: () => ({
    iconHappy: ICON_HAPPY,
    comment: ""
  }),
  props: {
    details: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Avatar,
    BtnSm,
    InputGroup,
    TagSubject,
    WrapperGallery,
    WrapperFiles,
    WrapperYoutube,
    WrapperComment
  },
  computed: {
    ...mapState(["user"]),
    images() {
      if (this.details.type == "course" || this.details.file_type == "img") {
        const gallery = this.details.files.map((item) => {
          return `${API_BASE_URL}/${item.file.file}`
        })
        return gallery
      }
    },
    privacy() {
      if (this.details.type == "course" && this.details.privacy) {
        return this.details.privacy == "group" ? "เฉพาะกลุ่ม" : this.details.privacy == "pulic" ? "สาธารณะ" : "เฉพาะฉัน"
      }
    }
  },
  methods: {
    goPage(page) {
      goPage(page)
    },
    async sendComment(e, id) {
      const {
        keyCode,
        target: { value }
      } = e
      if (keyCode == 13 && !!value.trim()) {
        const { code } = await axiosPost(API_COMMENT_CREATE, {
          token: this.user.token,
          post_id: id,
          comment: value.trim()
        })
        if (code == 200) {
          this.$refs.inputComment.value = ""
          this.$emit("processSuccess", true)
        }
      }
    },
    replySuccess() {
      this.fetchPost()
    },
    async likePost() {
      const { code } = await like(this.details.post_id)
      this.fetchPost()
    },
    async favoritePost() {
      const { code } = await favorite(this.details.post_id)
      this.fetchPost()
    },
    editPost() {
      goPage(`/${this.details.type == "post" ? "post" : "course"}/edit/${this.details.post_id}`)
    },
    async deletePost() {
      const { code } = await deletePost(this.details.post_id, this.details.type)
      this.fetchPost()
    },
    async join() {
      let isConfirm = true
      if (!this.details.ismember) {
        isConfirm = await confirmAlert(MSG_CONFIRM_JOIN)
      }
      if (isConfirm) {
        const { code, message } = await axiosPost(API_GROUP_JOIN, {
          token: this.user.token,
          user_id: this.user.userId,
          group_id: this.details.group_id
        })
        if (code == 200) {
          await successAlert(message)
        } else if (code == 302) {
          goPage(`/group/${this.details.group_id}`)
        } else {
          await errorAlert(message)
        }
      }
    },
    fetchPost() {
      this.$emit("processSuccess", true)
    }
  }
}
</script>
