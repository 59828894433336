<template>
  <v-list-group class="text-center w-100" :ripple="false" v-if="reply.length" append-icon>
    <template v-slot:activator>
      <p class="w-75 mx-auto separator">การตอบกลับ <span></span></p>
    </template>
    <ListComment
      v-for="(replyComment, i) in reply"
      :key="`reply-${i}`"
      :avatar="replyComment.img"
      :fullname="replyComment.fullname"
      :username="replyComment.username"
      :comment="replyComment.comment"
      :userId="userId"
    />
  </v-list-group>
</template>

<script>
import { mapState } from "vuex"
import { API_SUB_COMMENT_CREATE } from "../../constants/endpoints"
import { axiosPost } from "../../utils/api"
import { goPage } from "../../utils/app"
import ListComment from "../ListComment"
export default {
  props: {
    reply: {
      type: Array
    },
    userId: {
      type: String
    }
  },
  components: {
    ListComment
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    goPage(page) {
      goPage(page)
    },
    async sendReply(e, id, i) {
      const {
        keyCode,
        target: { value }
      } = e
      if (keyCode == 13 && !!value.trim()) {
        const { code } = await axiosPost(API_SUB_COMMENT_CREATE, {
          token: this.user.token,
          post_id: this.postId,
          comment_id: id,
          comment: value.trim()
        })
        if (code == 200) {
          this.$refs.inputSubComment[i].value = ""
          this.$emit("replySuccess", true)
        }
      }
    }
  }
}
</script>
