import axios from "axios"
import { API_URL, bearerToken } from "../constants/config"
import { logout } from "./app"

const tokenError = [410, 411, 412, 413]

const configAxios = (contentType = "application/json") => {
  return axios.create({
    baseURL: API_URL,
    headers: {
      "Content-Type": contentType,
      authorization: `Bearer ${bearerToken}`
    }
  })
}

const axiosGet = async (endpoint) => {
  try {
    return await configAxios().get(endpoint)
  } catch (error) {
    return error
  }
}

const axiosPost = async (endpoint, body) => {
  try {
    const { data } = await configAxios().post(endpoint, body)
    if (data.code && tokenError.indexOf(data.code) > -1) {
      await logout()
      return
    }
    return data
  } catch (error) {
    return error
  }
}

const axiosPut = async (endpoint, body, param) => {
  try {
    return await configAxios().put(endpoint + param, body)
  } catch (error) {
    return error
  }
}

const axiosDelete = async (endpoint, param) => {
  try {
    return await configAxios().delete(endpoint + param)
  } catch (error) {
    return error
  }
}

const formDataPost = async (endpoint, body) => {
  try {
    const contentType = "multipart/form-data"
    const { data } = await configAxios(contentType).post(endpoint, body)
    return data
  } catch (error) {
    return error
  }
}

export { axiosGet, axiosPost, axiosPut, axiosDelete, formDataPost }
