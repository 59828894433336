import { axiosPost } from "../../../utils/api"
import { API_RESET_PASSWORD, API_VALIDATE_PASSWORD } from "../../../constants/endpoints"
import { goPage } from "../../../utils/app"
import { passwordReset } from "../../../constants/form"
import { confirmAlert, errorAlert, successAlert } from "../../../utils/alert"
import { rules } from "../../../utils/validate"

import InputGroup from "../../../components/InputGroup.vue"
import BtnSpecial from "../../../components/buttons/BtnSpecial.vue"
export default {
    data: () => ({
        rules: {
            required: rules.required,
            password: rules.password,
            match: rules.match
        },
        isStarter: false,
        account: passwordReset,
        layout: "col-12 col-sm-8 col-md-8",
        isLoading: false,
        isDisabled: false
    }),
    components: {
        InputGroup,
        BtnSpecial
    },
    mounted() {
        this.verifyToken()
    },
    methods: {
        async verifyToken() {
            if (!this.$route.query.username || !this.$route.query.token_password) {
                goPage("/login")
                return
            }
            const { data: user, code, message } = await axiosPost(API_VALIDATE_PASSWORD, {
                username: this.$route.query.username,
                token_password: this.$route.query.token_password
            })

            if (code !== 200) {
                errorAlert(message)
                goPage("/login")
            } else {
                this.account.user_id = user.user_id
            }
        },
        async submit() {
            const isValid = this.$refs.resetForm.validate()
            if (isValid) {
                const isConfirm = await confirmAlert("ต้องการเปลี่ยนรหัสผ่านหรีอไม่?")
                if (isConfirm) {
                    const { code, message } = await axiosPost(API_RESET_PASSWORD, {
                        user_id: this.account.user_id,
                        password: this.account.password,
                        confirm_password: this.account.confirm_password
                    })
                    if (code == 200) {
                        await successAlert("เปลี่ยนรหัสผ่านสำเร็จ!")
                        goPage("/login")
                    } else {
                        errorAlert(message)
                    }
                }
            }
        }
    }
}
