<template>
  <div class="wrapper-files">
    <v-card
      v-for="(file, index) in files"
      :key="`file-${index}`"
      :href="`${apiURL + file.file.file}`"
      class="text-center middle block-file"
      target="_blank"
      :class="
        files.length == 1
          ? 'file-item-alone'
          : files.length == 2 && index == 0
          ? 'file-item-half-left'
          : files.length == 2 && index == 1
          ? 'file-item-half-right'
          : files.length == 3 && index == 0
          ? 'file-item-first'
          : files.length == 3 && index == 1
          ? 'file-item-second'
          : 'file-item-third'
      "
    >
      <v-icon class="flipped" size="30">mdi-attachment mdi-rotate-45</v-icon>
        <small>คลิกเพื่อดูไฟล์</small> 
    </v-card>
  </div>
</template>

<script>
import { API_BASE_URL } from "../../constants/config"
export default {
  data: () => ({
    apiURL: API_BASE_URL
  }),
  props: {
    files: {
      type: Array,
      default: () => []
    }
  }
}
</script>
