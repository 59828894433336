import { API_URL,_URL } from "@/constants/config";
import VueGallerySlideshow from 'vue-gallery-slideshow';
export default {
    data() {
        return {
            search: '',
            id: 1,
            stepper: 1,
            steps: [{
                completed: false
            },
            {
                completed: false
            },
            {
                completed: false
            }
            ],
            privacy: [{ status: 'public', text: 'สาธารณะ' }, { status: 'private', text: 'เฉพาะฉัน' }],
            item: [{ id: 1, name: "Keep me signed in" }],
            imgPreview: '',
            postId: this.$route.params.id,
            token: localStorage.getItem('token'),
            post: {
                token: localStorage.getItem("token"),
                caption: null,
                title: null,
                subject: null,
                course_id: null,
                description: null,
                privacy: 'public',
                files: [],
                file_cover: [],
                file_cover_type: [],
                chapter: []
            },
            imgIndex: null,
            imgPreview: null
        }
    },
    components: {
        VueGallerySlideshow
    },
    created() {
        this.fetchCourse()
    },
    methods: {
        fetchCourse() {
            const body = { token: this.token, post_id: this.postId };
            this.axios
                .post(`${API_URL}/api/course`, body, {
                    headers: header,
                })
                .then((res) => {
                    const { code, data: data, message } = res.data;
                    if (code == 411 || code == 412 || code == 413) {
                        this.autoLogout()
                    } else if (code == 301 || code == 302 || code == 305) {
                        alert(message)
                    } else if (code == 500) {
                        //alert(message)
                    } else {
                        this.post = {
                            token: localStorage.getItem("token"),
                            caption: data.caption,
                            title: data.title,
                            subject: data.subject,
                            post_id: data.post_id,
                            course_id: data.course_id,
                            description: data.description,
                            privacy: data.privacy,
                            file_cover: [],
                            file_cover_type: [],
                            chapter: data.chapter
                        }
                        const cover = data.files[0].file.file
                        this.imgPreview = `${API_URL}/${cover}`
                    }
                })
        },
        toTop() {
            window.scrollTo(0, 0);
        },
        pushChapter() {
            this.post.chapter.push({
                title: null,
                sub_chapter: []
            })
        },
        deleteChapter(index) {
            if (confirm('ต้องการลบบทเรียนนี้ใช่หรือไม่')) {
                this.post.chapter.splice(index, 1)
            }
        },
        pushSubChapter(index) {
            this.post.chapter[index].sub_chapter.push({
                title: null,
                video: [],
                files: []
            })
        },
        deleteSubChapter(chapterIndex, subIndex) {
            if (confirm('ต้องการลบหัวข้อนี้ใช่หรือไม่')) {
                this.post.chapter[chapterIndex].sub_chapter.splice(subIndex, 1)
            }
        },
        addLink(chapterIndex, subIndex) {
            this.post.chapter[chapterIndex].sub_chapter[subIndex].video.push({
                title: null,
                url: null
            })
        },
        deleteLink(chapterIndex, subIndex, index) {
            if (confirm('ต้องการลบลิงก์นี้ใช่หรือไม่')) {
                this.post.chapter[chapterIndex].sub_chapter[subIndex].video.splice(index, 1)
            }
        },
        addFile(chapterIndex, subIndex) {
            this.post.chapter[chapterIndex].sub_chapter[subIndex].files.push({
                title: null,
                file: null,
                file_type: null,
                file_name: null
            })
        },
        deleteFile(chapterIndex, subIndex, index) {
            if (confirm('ต้องการลบไฟล์นี้ใช่หรือไม่')) {
                this.post.chapter[chapterIndex].sub_chapter[subIndex].files.splice(index, 1)
            }
        },
        onChangeImg(e) {
            var files = e.target.files || e.dataTransfer.files;
            const extension = files[0].type.split("/")[1];
            if (extension == 'jpg' || extension == 'jpeg' || extension == 'png') {
                this.createBase64(files[0], extension)
            } else {
                alert('สามารถเพิ่มได้เฉพาะไฟล์ประเภท .jpg .jpeg .png เท่านั้น')
            }
        },
        createBase64(fileInp, ext) {
            const reader = new FileReader();
            if (this.post.file_cover.length > 0) {
                this.post.file_cover.pop()
                this.post.file_cover_type.pop()
            }
            reader.onload = (e) => {
                const file = e.target.result;
                this.post.file_cover.push(file)
                this.post.file_cover_type.push(ext)
                this.imgPreview = file
            };
            reader.readAsDataURL(fileInp);
        },
        onChangeFile(e, index, indexSub, indexFile) {

            var files = e.target.files || e.dataTransfer.files;
            const extension = files[0].type.split("/")[1];
            var filename = this.post.chapter[index].sub_chapter[indexSub].files[indexFile].file_name;
            if (filename == null) {
                filename = files[0].name
            }
            if (extension == 'pdf') {
                this.generateFile(files[0], extension, filename, index, indexSub, indexFile)
            } else {
                alert('สามารถเพิ่มได้เฉพาะไฟล์ประเภท .pdf เท่านั้น')
            }
        },
        generateFile(inpFile, ext, name, index, indexSub, indexFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const file = e.target.result;
                if (ext == "pdf") {
                    this.post.chapter[index].sub_chapter[indexSub].files[indexFile].file = file.split(",")[1]
                    this.post.chapter[index].sub_chapter[indexSub].files[indexFile].file_type = ext
                    this.post.chapter[index].sub_chapter[indexSub].files[indexFile].file_name = name
                } else {
                    alert('สามารถเพิ่มได้เฉพาะไฟล์ประเภท .pdf เท่านั้น')
                }
            };
            reader.readAsDataURL(inpFile);

        },
        removeImg(i) {
            this.post.file.splice(i, 1)
        },
        savePost() {
            this.axios
                .post(`${API_URL}/api/course/update`, this.post, {
                    headers: header,
                })
                .then((result) => {
                    const { data: { code, message } } = result
                    if (code == 411 || code == 412 || code == 413) {
                        this.autoLogout()
                    } else if (code == 301 || code == 302 || code == 305) {
                        alert(message)
                    } else if (code == 500) {
                        //alert(message)
                    } else {
                        alert('บันทึกโพสต์สำเร็จ')
                        location.reload()
                    }
                }).catch(err => {
                    alert('ระบบขัดข้อง กรุณาลองใหม่อีกครั้ง')
                })
        },
        autoLogout() {
            OneSignal.deleteTag("user_id");
            const token = { token: this.token };

            this.axios
                .post(`${API_URL}/api/logout`, token, {
                    headers: header,
                })
                .then((result) => {
                    const { code, data: data, message } = result.data;
                    if (code !== 200) {
                        localStorage.clear();
                        this.$router.push({
                            path: '/login'
                        });
                    } else {
                        localStorage.clear();
                        this.$router.push({
                            path: '/login'
                        });
                    }
                })
        },
    }
}