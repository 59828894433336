import router from "../router"
import store from "../store"
import { disableNotification } from "./onesignal"

const goPage = (path) => {
  router.push(path)
}

const yearPrev = (length = 60) => {
  let max = new Date().getFullYear()
  let min = max - length
  let years = []

  for (let i = max; i >= min; i--) {
    years.push(i)
  }
  return years
}

const yearNext = (length = 9) => {
  let current = new Date().getFullYear()
  let max = current + length
  let years = []

  for (let i = current; i < max; i++) {
    years.push(i)
  }
  return years
}

const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

const getCurrent = () => {
  let current = {
    date: "",
    time: ""
  }
  let today = new Date()
  current.date =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    today
      .getDate()
      .toString()
      .padStart(2, "0")
  current.time =
    today
      .getHours()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getMinutes()
      .toString()
      .padStart(2, "0")
  return current
}

const generateArrayShift = (length = 32) => {
  const items = Array.from(Array(length).keys())
  items.shift()
  return items
}

const logout = async () => {
  store.commit("CLEAR_AUTH")
  if (!store.getters.doneAuth) {
    disableNotification()
    goPage("/login")
  }
}

const createBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export { goPage, yearPrev, yearNext, month, getCurrent, generateArrayShift, logout, createBase64 }
