import { mapState } from "vuex"

import { axiosPost } from "../../../utils/api"
import { API_FEED_FAVORITE } from "../../../constants/endpoints"

import CardPost from "../../../components/CardPost.vue"
export default {
    data: () => ({
        feed: [],
        isStarter: false
    }),
    mounted() {
        this.fetchFeed()
    },
    components: {
        CardPost
    },
    computed: {
        ...mapState(["user"])
    },
    methods: {
        async fetchFeed() {
            const { data: posts, code } = await axiosPost(API_FEED_FAVORITE, {
                token: this.user.token,
                user_id: this.user.userId
            })

            if (code == 200) {
                this.feed = posts
            }

            this.isStarter = true
        },
        processSuccess() {
            this.fetchFeed()
        },
    }
}