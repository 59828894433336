var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper-gallery"},[_vm._l((_vm.gallery),function(image,index){return _c('figure',{key:image.file._id,staticClass:"gallery-item",class:_vm.gallery.length == 1
        ? 'gallery-item-cover'
        : _vm.gallery.length == 2 && index == 1
        ? 'gallery-item-half-left'
        : _vm.gallery.length == 2 && index == 2
        ? 'gallery-item-half-left'
        : _vm.gallery.length == 3 && index == 1
        ? 'gallery-item-large-left'
        : _vm.gallery.length == 3 && index == 2
        ? 'gallery-item-small-top'
        : 'gallery-item-small-bottom',on:{"click":function($event){return _vm.openImg(index)}}},[_c('img',{staticClass:"gallery-img",attrs:{"src":(_vm.apiURL + "/" + (image.file.file)),"alt":"Image 1"}})])}),(_vm.images.length)?_c('VueGallerySlideshow',{attrs:{"images":_vm.images,"index":_vm.imgIndex},on:{"close":_vm.close}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }