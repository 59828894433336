<template>
  <v-card class="preview-img" :class="{'preview-img-large': large}">
    <v-btn class="icon-close white" icon small fab @click="onclickClose()" v-if="!disabledClose">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-img class="fill-height" :src="img" :max-width="maxWidth" :max-height="maxHeight">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </v-card>
</template>

<script>
import { MSG_CONFIRM_CHANGE_DELETE } from "../constants/messages"
import { confirmAlert } from "../utils/alert"
export default {
  props: {
    img: {
      type: String,
      default: () => ""
    },
    maxWidth: {
      type: Number,
      default: () => 500
    },
    maxHeight: {
      type: Number,
      default: () => 500
    },
    disabledClose: {
      type: Boolean,
      default: () => false
    },
    large: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    async onclickClose() {
      const isConfirm = await confirmAlert(MSG_CONFIRM_CHANGE_DELETE)
      if (isConfirm) {
        this.$emit("onclickClose", true)
      }
    }
  }
}
</script>
