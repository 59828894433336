<template>
  <div>
    <aside class="sidebar sidbar-sm" app>
      <router-link to="/">
        <img class="d-block mx-auto mb-2 side-logo" :src="imgLogo" />
      </router-link>
      <v-card class="my-6 list-route card-profile-column">
        <v-list-item class="profile-list">
          <Avatar class="ma-auto" :image="user.img" :link="`/profile/timeline/${user.userId}`" :size="70" />
        </v-list-item>
        <v-list-item class="px-1" @click="goPage(`/profile/timeline/${user.userId}`)">
          <v-list-item-content
            ><a> @{{ user.username }} </a>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-list-item class="mb-8 px-0" v-if="user.type !== 'student'">
        <v-btn class="secondary-color btn-height-md" @click="goPage('/course')">
          <img :src="iconBook" class="add-course" alt="avatar" />
          <p class="mb-0 ml-2 text-link">สร้างรายวิชา</p>
        </v-btn>
      </v-list-item>
      <ul class="list-group">
        <li :class="{ active: $route.name == 'feed' }">
          <router-link to="/" class="d-flex">
            <v-icon class="icon-link">mdi-home</v-icon>
            <h5 class="text-link">หน้าแรก</h5>
          </router-link>
        </li>
        <li :class="{ active: $route.name == 'notification' }">
          <router-link to="/notification" class="d-flex">
            <Badge :count="numberNotiUnread">
              <h5 class="mt-auto mb-auto text-link">แจ้งเตือน</h5>
            </Badge>
          </router-link>
        </li>
        <li :class="{ active: $route.name == 'saved' }">
          <router-link to="/saved" class="d-flex">
            <v-icon class="icon-link">mdi-bookmark</v-icon>
            <h5 class="text-link">บันทึกไว้</h5>
          </router-link>
        </li>
        <li :class="{ active: $route.name == 'message' }">
          <!-- <router-link to="/message" class="d-flex"> -->
          <router-link to="#" class="d-flex">
            <!-- <v-badge color="error" content="6" class="d-flex" offset-y="20" offset-x="45"> -->
            <v-icon class="icon-link">mdi-chat</v-icon>
            <h5 class="mt-auto mb-auto text-link">ข้อความ</h5>
            <!-- </v-badge> -->
          </router-link>
        </li>
        <li :class="{ active: $route.name == 'AllGroup' }">
          <router-link to="/group" class="d-flex">
            <v-icon class="icon-link">mdi-account-group</v-icon>
            <h5 class="text-link">กลุ่มทั้งหมด</h5>
          </router-link>
        </li>
        <v-divider class="mb-4"></v-divider>
        <li :class="{ active: $route.name == 'setting' }">
          <router-link to="#" class="d-flex">
            <v-icon class="icon-link">mdi-cog</v-icon>
            <h5 class="text-link">ตั้งค่า</h5>
          </router-link>
        </li>
        <li :class="{ active: $route.name == 'help' }">
          <router-link to="#" class="d-flex">
            <v-icon class="icon-link">mdi-help-circle</v-icon>
            <h5 class="text-link">ช่วยเหลือ</h5>
          </router-link>
        </li>
        <li @click="logout()">
          <a class="d-flex">
            <v-icon class="icon-link" @click="logout()">mdi-power</v-icon>
            <h5 class="text-link">ออกจากระบบ</h5>
          </a>
        </li>
      </ul>
    </aside>
    <ModalCreatePost v-model="visibleDialog" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { ICON_COURSE, IMG_LOGO, ICON_BOOK } from "../constants/media"
import { logout } from "../utils/app"
import { goPage } from "../utils/app"

import Avatar from "../components/Avatar"
import Badge from "../components/Badge"
import ModalCreatePost from "../components/modal/ModalCreatePost"
export default {
  data: () => ({
    imgLogo: IMG_LOGO,
    iconCourse: ICON_COURSE,
    iconBook: ICON_BOOK,
    right: null,
    visibleDialog: false
  }),
  components: {
    Avatar,
    Badge,
    ModalCreatePost
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["numberNotiUnread"])
  },
  methods: {
    logout: logout,
    goPage(val) { goPage(val) }
  }
}
</script>
