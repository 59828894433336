const MSG_CREATE_SUCCESS = "สร้างสำเร็จ!"
const MSG_ADD_SUCCESS = "เพิ่มสำเร็จ!"
const MSG_UPDATE_SUCCESS = "เปลี่ยนแปลงสำเร็จ!"
const MSG_DELETE_SUCCESS = "ลบสำเร็จ!"
const MSG_REJECT_SUCCESS = "ปฏิเสธสำเร็จ!"
const MSG_FOLLOW_SUCCESS = "ติดตามสำเร็จ!"
const MSG_UNFOLLOW_SUCCESS = "เลิกติดตามสำเร็จ!"
const MSG_CHANGE_SUCCESS = "เปลี่ยนแปลงสำเร็จ!"
const MSG_LOGIN_FAILED = "เข้าสู่ระบบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง!"
const MSG_ADD_FAILED = "เพิ่มสำเร็จ!"
const MSG_ERROR = "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง!"
const MSG_ERROR_FILE_TYPE = "เลือกได้เฉพาะ"
const MSG_CONFIRM_ADD = "ต้องการเพิ่ม? "
const MSG_CONFIRM_SAVE = "ต้องการบันทึก "
const MSG_CONFIRM_CHANGE = "ต้องการเปลี่ยนแปลง "
const MSG_CONFIRM_CHANGE_DELETE = "ต้องการลบ?"
const MSG_CONFIRM_UNFOLLOW = "ต้องการเลิกติดตาม?"
const MSG_CONFIRM_JOIN = "ต้องการขอเข้าร่วม?"
const MSG_CONFIRM_REJECT = "ต้องการปฏิเสธ?"
const MSG_VALIDATE_IMAGE = "โปรดเลือกไฟล์ .png .jpg .jpeg และขนาด น้อยกว่า 5 MB"
const MSG_VALIDATE_FILE = "โปรดเลือกไฟล์ .pdf และขนาด น้อยกว่า 5 MB"

export {
  MSG_CREATE_SUCCESS,
  MSG_ADD_SUCCESS,
  MSG_UPDATE_SUCCESS,
  MSG_DELETE_SUCCESS,
  MSG_REJECT_SUCCESS,
  MSG_FOLLOW_SUCCESS,
  MSG_UNFOLLOW_SUCCESS,
  MSG_CHANGE_SUCCESS,
  MSG_LOGIN_FAILED,
  MSG_ADD_FAILED,
  MSG_ERROR,
  MSG_ERROR_FILE_TYPE,
  MSG_CONFIRM_ADD,
  MSG_CONFIRM_SAVE,
  MSG_CONFIRM_CHANGE,
  MSG_CONFIRM_CHANGE_DELETE,
  MSG_CONFIRM_UNFOLLOW,
  MSG_CONFIRM_JOIN,
  MSG_CONFIRM_REJECT,
  MSG_VALIDATE_IMAGE,
  MSG_VALIDATE_FILE
}
