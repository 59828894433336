import { IMG_LOGO } from "../../constants/media"
import CardPost from "../../components/CardPost.vue"
import { mapState } from "vuex"
import { axiosPost } from "../../utils/api"
import { API_FEED, API_POST_DEFAULT } from "../../constants/endpoints"
export default {
  data: () => ({
    imgLogo: IMG_LOGO,
    feed: [],
    isStarter: false
  }),
  mounted() {
    this.loadFeed()
  },
  components: {
    CardPost
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    loadFeed() {
      if (this.user.following > 0) {
        this.fetchFeed()
      } else {
        this.fetchFeedDefault()
      }
    },
    async fetchFeed() {
      const { data: feed } = await axiosPost(API_FEED, { token: this.user.token })
      if (feed.length) {
        this.feed = feed
        this.isStarter = true
      } else {
        this.fetchFeedDefault()
      }
    },
    async fetchFeedDefault() {
      const { data: feed } = await axiosPost(API_POST_DEFAULT, { token: this.user.token })
      if (feed.length) {
        this.feed = feed
        this.isStarter = true
      }
    },
    processSuccess(val) {
      if (val) {
        this.loadFeed()
      }
    }
  }
}
