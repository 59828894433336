import Vue from "vue"
import Vuex from "vuex"

import { API_NOTIFICATION_COUNT } from "../constants/endpoints"
import { axiosPost } from "../utils/api"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAuth: localStorage.getItem("isAuth") || false,
    isSetup: localStorage.getItem("isSetup") || false,
    user: {
      userId: localStorage.getItem("userId") || null,
      username: localStorage.getItem("username") || null,
      fullname: localStorage.getItem("fullname") || null,
      type: localStorage.getItem("type") || null,
      certificate: localStorage.getItem("certificate") || null,
      img: localStorage.getItem("img") || null,
      cover: localStorage.getItem("cover") || null,
      sex: localStorage.getItem("sex") || null,
      following: localStorage.getItem("following") || null,
      token: localStorage.getItem("token") || null,
      register_by: localStorage.getItem("register_by") || null
    },
    notiCount: localStorage.getItem("notiCount") || 0
  },
  getters: {
    doneAuth: (state) => {
      if (state.isAuth || state.isAuth == "true") {
        return true
      } else {
        return false
      }
    },
    numberNotiUnread: (state) => {
      return state.notiCount.toString()
    }
  },
  mutations: {
    SET_AUTH: (state, payload) => {
      state.isAuth = true
      state.user = {
        userId: payload.user_id,
        username: payload.username,
        fullname: payload.fullname,
        type: payload.type,
        certificate: payload.certificate,
        img: payload.img,
        cover: payload.cover_img,
        sex: payload.sex,
        following: payload.following,
        token: payload.token,
        register_by: payload.register_by
      }
      localStorage.setItem("isAuth", true)
      localStorage.setItem("userId", payload.user_id)
      localStorage.setItem("username", payload.username)
      localStorage.setItem("fullname", payload.fullname)
      localStorage.setItem("type", payload.type)
      localStorage.setItem("certificate", payload.certificate)
      localStorage.setItem("img", payload.img)
      localStorage.setItem("cover", payload.cover_img)
      localStorage.setItem("sex", payload.sex)
      localStorage.setItem("following", payload.following)
      localStorage.setItem("token", payload.token)
      localStorage.setItem("register_by", payload.register_by)
    },
    CLEAR_AUTH: (state) => {
      state.isAuth = false
      localStorage.removeItem("isAuth")
      localStorage.removeItem("userId")
      localStorage.removeItem("username")
      localStorage.removeItem("fullname")
      localStorage.removeItem("type")
      localStorage.removeItem("certificate")
      localStorage.removeItem("img")
      localStorage.removeItem("cover")
      localStorage.removeItem("sex")
      localStorage.removeItem("following")
      localStorage.removeItem("token")
      localStorage.removeItem("register_by")
    },
    SET_NOTI_COUNT: (state, payload) => {
      state.notiCount = payload
    }
  },
  actions: {
    async fetchNotiCount({ commit, state }) {
      const { data, code } = await axiosPost(API_NOTIFICATION_COUNT, { token: state.user.token })
      if (code == 200) {
        commit("SET_NOTI_COUNT", data)
      }
    }
  }
})
