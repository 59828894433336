<template>
  <ModalBase v-model="isActive" :maxWidth="700" :title="title">
    <template v-slot:body>
      <v-form v-model="valid" ref="groupForm" lazy-validation>
        <v-row class="my-4 justify-center">
          <InputGroup
            v-model="group.name"
            label="ชื่อกลุ่ม"
            :layout="layout"
            :validates="[rules.required]"
            color="primary"
          />
          <TextArea
            v-model="group.description"
            label="คำอธิบายกลุ่ม"
            :layout="layout"
            :validates="[rules.required]"
            color="primary"
          />
        </v-row>
      </v-form>
    </template>
    <template v-slot:footer>
      <BtnSm
        class="btn-primary btn-rounded-pill"
        @click.native="submit()"
        :isLoading="isLoading"
        :isDisabled="isLoading"
        >สร้าง</BtnSm
      >
    </template>
  </ModalBase>
</template>

<script>
import { mapState } from "vuex"
import { groupForm } from "../../constants/form"
import { API_GROUP_CREATE } from "../../constants/endpoints"
import { MSG_CONFIRM_SAVE, MSG_CREATE_SUCCESS } from "../../constants/messages"
import { goPage } from "../../utils/app"
import { confirmAlert, errorAlert, successAlert } from "../../utils/alert"
import { axiosPost } from "../../utils/api"
import { rules } from "../../utils/validate"

import ModalBase from "./ModalBase"
import InputGroup from "../InputGroup"
import TextArea from "../TextArea"
import BtnSm from "../buttons/BtnSm"
export default {
  data: () => ({
    group: groupForm,
    valid: false,
    layout: "col-12 col-sm-8 col-md-8",
    rules: {
      required: rules.required
    },
    isLoading: false
  }),
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    title: {
      type: String
    }
  },
  components: {
    ModalBase,
    InputGroup,
    TextArea,
    BtnSm
  },
  computed: {
    ...mapState(["user"]),
    isActive: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },
  methods: {
    async submit() {
      const isConfirm = await confirmAlert(MSG_CONFIRM_SAVE)
      if (isConfirm) {
        const isValid = this.$refs.groupForm.validate()
        if (isValid) {
          this.isLoading = true
          const { data: group, code, message } = await axiosPost(API_GROUP_CREATE, {
            token: this.user.token,
            name: this.group.name,
            description: this.group.description
          })
          if (code == 200) {
            await successAlert(MSG_CREATE_SUCCESS)
            const { group_id } = group
            goPage(`/group/${group_id}`)
          } else {
            await errorAlert(message)
          }
          this.isLoading = false
        }
      }
    }
  }
}
</script>
