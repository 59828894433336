var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper-files"},_vm._l((_vm.files),function(file,index){return _c('v-card',{key:("file-" + index),staticClass:"text-center middle block-file",class:_vm.files.length == 1
        ? 'file-item-alone'
        : _vm.files.length == 2 && index == 0
        ? 'file-item-half-left'
        : _vm.files.length == 2 && index == 1
        ? 'file-item-half-right'
        : _vm.files.length == 3 && index == 0
        ? 'file-item-first'
        : _vm.files.length == 3 && index == 1
        ? 'file-item-second'
        : 'file-item-third',attrs:{"href":("" + (_vm.apiURL + file.file.file)),"target":"_blank"}},[_c('v-icon',{staticClass:"flipped",attrs:{"size":"30"}},[_vm._v("mdi-attachment mdi-rotate-45")]),_c('small',[_vm._v("คลิกเพื่อดูไฟล์")])],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }