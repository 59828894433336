<template>
  <div class="d-flex">
    <SideBarSm />
    <ToolbarTopResponsive class="d-block d-sm-none" />
    <v-main class="wrapper-main wrapper-edit">
      <div class="main-container">
        <v-container>
          <ToolbarSearch class="d-none d-sm-flex mb-10" />
          <div class="course-all">
            <v-row>
              <v-col class="col-12 d-flex justify-center">
                <div class="d-flex middle underline ml-auto">
                  <v-icon class="mr-2">mdi-account-group</v-icon>
                  <h5 class="end">กลุ่มทั้งหมด</h5>
                </div>
                <BtnSm class="btn-primary btn-rounded-pill ml-auto" @click.native="openModal()" v-if="user.type == 'teacher'">
                  <v-icon>mdi-plus</v-icon> สร้างกลุ่ม
                </BtnSm>
              </v-col>
            </v-row>
            <v-row v-if="groups.length">
              <v-col class="col-6 col-md-4 text-center" v-for="(group, index) in groups" :key="`group-${index}`">
                <CoverPreview
                  class="mb-4"
                  :img="`${apiURL}/${group.cover}`"
                  disabledClose
                  @click.native="goPage(`/group/${group.group_id}`)"
                >
                  <template v-slot:tools> </template>
                </CoverPreview>
                <h5 class="mb-2 text-center">
                  {{ group.name }}
                </h5>
                <BtnXs class="btn-primary btn-rounded-pill" @click.native="join(group.group_id)"> เข้าร่วม </BtnXs>
              </v-col>
            </v-row>
            <div class="mt-10 text-center" v-else>
              <h1 class="grey--text">ไม่มี กลุ่ม</h1>
            </div>
          </div>
        </v-container>
      </div>
    </v-main>
    <ModalCreateGroup v-model="visibleModal" title="สร้างกลุ่ม" />
    <ToolbarBottomResponsive />
  </div>
</template>

<script>
import { mapState } from "vuex"
import { API_BASE_URL } from "../../constants/config"
import { ICON_COURSE_BLACK } from "../../constants/media"
import { API_GROUP, API_GROUP_JOIN } from "../../constants/endpoints"
import { goPage } from "../../utils/app"
import { axiosPost } from "../../utils/api"

import BtnXs from "../../components/buttons/BtnXs"
import BtnSm from "../../components/buttons/BtnSm"
import CoverPreview from "../../components/CoverPreview"
import ImgPreview from "../../components/ImgPreview"
import SideBarSm from "../../components/SideBarSm"
import ToolbarSearch from "../../components/toolbars/ToolbarSearch"
import ToolbarTopResponsive from "../../components/toolbars/ToolbarTopResponsive"
import ToolbarBottomResponsive from "../../components/toolbars/ToolbarBottomResponsive"
import ModalCreateGroup from "../../components/modal/ModalCreateGroup"
import { confirmAlert, errorAlert, successAlert } from "../../utils/alert"
import { MSG_CONFIRM_JOIN } from "../../constants/messages"
export default {
  data: () => ({
    apiURL: API_BASE_URL,
    iconCourseBlack: ICON_COURSE_BLACK,
    id: null,
    groups: [],
    visibleModal: false
  }),
  components: {
    BtnSm,
    BtnXs,
    CoverPreview,
    ImgPreview,
    SideBarSm,
    ToolbarSearch,
    ToolbarTopResponsive,
    ToolbarBottomResponsive,
    ModalCreateGroup
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
    }
    this.fetchGroup()
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    async fetchGroup() {
      const { data: groups } = await axiosPost(API_GROUP, { token: this.user.token })
      if (groups.length) {
        this.groups = groups
      }
    },
    openModal() {
      this.visibleModal = !this.visibleModal
    },
    async join(id) {
      const isConfirm = await confirmAlert(MSG_CONFIRM_JOIN)
      if (isConfirm) {
        const { code, message } = await axiosPost(API_GROUP_JOIN, {
          token: this.user.token,
          user_id: this.user.userId,
          group_id: id
        })
        if (code == 200) {
          await successAlert(message)
          this.fetchGroup()
        } else if (code == 302) {
          goPage(`/group/${id}`)
        } else {
          await errorAlert(message)
        }
      }
    },
    goPage(path) {
      goPage(path)
    }
  }
}
</script>
