<template>
  <v-btn class="btn-sm btn-follow btn-rounded-pill" :class="isActive ? 'btn-primary-outline' : 'btn-primary'">
    <v-icon v-if="!isActive">mdi-plus</v-icon>
    <p>{{ isActive ? "ติดตามแล้ว" : "ติดตาม" }}</p>
  </v-btn>
</template>

<script>
import BtnSm from "../buttons/BtnSm"
export default {
  components: {
    BtnSm
  },
  props: {
    isActive: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>
