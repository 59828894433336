<template>
  <ModalBase v-model="isActive" :maxWidth="980" :title="title">
    <template v-slot:body>
      <v-tabs-items class="text-center" v-model="tab">
        <v-tab-item :key="1">
          <v-container>
            <v-row>
              <v-list-item class="profile-list d-flex">
                <Avatar :image="user.img" :size="50" @click.native="goPage(`/profile/timeline/${user.userId}`)" />
                <v-list-item-content class="text-start" @click="goPage(`/profile/timeline/${user.userId}`)">
                  <v-list-item-title>
                    <h5>{{ user.fullname }}</h5>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <p class="mb-0">@{{ user.username }}</p>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-form class="w-100" v-model="form[0].valid" :ref="form[0].ref" lazy-validation>
                <TextArea
                  v-model="post.title"
                  class="pt-4 pb-2"
                  placeholder="เขียนโพสต์..."
                  :validates="[rules.required]"
                />
              </v-form>
              <v-flex xs12></v-flex>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item :key="2">
          <v-card-text>
            <v-container>
              <v-form class="w-100" v-model="form[1].valid" :ref="form[1].ref" lazy-validation>
                <TextArea
                  v-model="post.title"
                  :row="3"
                  placeholder="เขียนโพสต์..."
                  :validates="[rules.required]"
                />
                <v-row class="justify-center">
                  <v-col class="col-auto" v-for="(img, index) in imgPreview" :key="index">
                    <ImgPreview :img="img" @onclickClose="removeImage(index)" />
                  </v-col>
                  <v-flex xs12></v-flex>
                  <v-flex xs12></v-flex>
                  <v-col class="col-12">
                    <BoxUpload @onchangeHandler="onchangeHandlerImg" v-if="imgPreview.length < 3" />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-tab-item>
        <v-tab-item :key="3">
          <v-container>
            <v-form class="w-100" v-model="form[0].valid" :ref="form[0].ref" lazy-validation>
              <v-row>
                <v-col class="col-12 pa-0">
                  <TextArea
                    v-model="post.title"
                    :row="3"
                    placeholder="เขียนโพสต์..."
                    :validates="[rules.required]"
                  />
                </v-col>
                <InputGroup
                  v-model="linkYoutube"
                  layout="col-12 pb-4"
                  label="เพิ่มลิงก์ Youtube..."
                  placeholder="เพิ่มลิงก์ Youtube..."
                />
                <v-flex xs12></v-flex>
                <v-col class="col-12 col-md-5 col-sm-6 text-left" v-if="post.link">
                  <p>ตัวอย่าง</p>
                  <iframe
                    class="video-frame"
                    :src="`https://www.youtube.com/embed/${post.link}`"
                    width="100%"
                    height="220px"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-tab-item>
        <v-tab-item :key="4">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col class="col-12 pa-0">
                  <TextArea
                    v-model="post.title"
                    class="pt-4 pb-2"
                    placeholder="เขียนโพสต์..."
                    :validates="[rules.required]"
                    noOutlined
                  />
                </v-col>
                <v-flex xs12></v-flex>
                <v-col class="col-12 pt-0 pb-0">
                  <BoxUpload @onchangeHandler="onchangeHandlerFile" v-if="fileList.length < 3">
                    <template v-slot="{ on }">
                      <v-card class="card-upload" v-on="on" outlined>
                        <v-icon class="flipped">mdi-attachment mdi-rotate-45</v-icon>
                        <h4><span class="primary--text">เลือกไฟล์</span>หรือวางไฟล์ที่นี่</h4>
                        <small>(สูงสุด 3 ไฟล์)</small>
                      </v-card>
                    </template>
                  </BoxUpload>
                </v-col>
                <v-col
                  class="col-12 py-1 d-flex justify-space-between"
                  v-for="(file, index) in fileList"
                  :key="`file-${index}`"
                >
                  <p>{{ file.name }}</p>
                  <v-spacer></v-spacer>
                  <p>{{ file.size | filterSize }}</p>
                  <v-icon color="black" class="mb-auto" @click="removeFile(index)">mdi-close</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
      <v-divider class="my-4 px-3"></v-divider>
      <v-tabs v-model="tab" hide-slider class="tabs-bottom mt-0 px-4">
        <v-tab :key="1" @click="changeTab(0)">
          <v-icon class="primary--text">mdi-pencil</v-icon>
        </v-tab>
        <v-tab :key="2" @click="changeTab(1)">
          <img :src="iconPost" alt />
        </v-tab>
        <v-tab :key="3" @click="changeTab(2)">
          <v-btn class="elevation-0 btn-youtube">
            <v-icon color="white" size="40">mdi-menu-right</v-icon>
          </v-btn>
        </v-tab>
        <v-tab :key="4" @click="changeTab(3)">
          <img :src="iconClip" alt />
        </v-tab>
        <BtnSm
          class="btn-primary btn-rounded-pill d-none d-sm-inline-flex ml-auto"
          :isLoading="isLoading"
          :isDisabled="isLoading"
          @click.native="submit()"
        >
          โพสต์
        </BtnSm>
      </v-tabs>
    </template>
    <template v-slot:footer>
      <BtnSm class="btn-primary btn-rounded-pill d-sm-none" @click.native="submit()">โพสต์</BtnSm>
    </template>
  </ModalBase>
</template>

<script>
import { mapState } from "vuex"

import { ICON_UPLOAD, ICON_POST, ICON_CLIP } from "../../constants/media"
import { postForm } from "../../constants/form"
import { postTab } from "../../constants/app"
import { rules } from "../../utils/validate"
import { createPost } from "../../services/post"

import { goPage } from "../../utils/app"

import ModalBase from "./ModalBase"
import Avatar from "../Avatar"
import TextArea from "../TextArea"
import InputGroup from "../InputGroup"
import BtnSm from "../buttons/BtnSm"
import ImgPreview from "../ImgPreview"
import BoxUpload from "../BoxUpload"
import { errorAlert, successAlert } from '../../utils/alert'
import { MSG_CREATE_SUCCESS, MSG_VALIDATE_FILE, MSG_VALIDATE_IMAGE } from '../../constants/messages'

let formData = new FormData()

export default {
  data: () => ({
    iconUpload: ICON_UPLOAD,
    iconPost: ICON_POST,
    iconClip: ICON_CLIP,
    tab: 0,
    title: postTab[0],
    post: postForm,
    imgPreview: [],
    fileList: [],
    linkYoutube: "",
    file: {},
    form: [
      {
        valid: false,
        ref: "from1"
      },
      {
        valid: false,
        ref: "from2"
      },
      {
        valid: false,
        ref: "from3"
      },
      {
        valid: false,
        ref: "from4"
      },
    ],
    rules: {
      required: rules.required
    },
    isLoading: false
  }),
  components: {
    ModalBase,
    Avatar,
    TextArea,
    InputGroup,
    BtnSm,
    ImgPreview,
    BoxUpload
  },
  props: {
    value: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState(["user"]),
    isActive: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },
  methods: {
    changeTab(index) {
      this.title = postTab[index]
      this.post.title = null
      this.imgPreview = []
      this.fileList = []
      this.post.link = null
      this.tab = index
      formData = new FormData()
    },
    async onchangeHandlerImg(image) {
      const imgExt = ["image/png", "image/jpg", "image/jpeg"]
      if (image.type && imgExt.includes(image.type) && image.size < 5000000) {
        formData.append("image", image)
        this.imgPreview.push(URL.createObjectURL(image))
      } else {
        await errorAlert(MSG_VALIDATE_IMAGE)
      }
    },
    async onchangeHandlerFile(file) {
      const imgExt = ["application/pdf"]
      if (file.type && imgExt.includes(file.type) && file.size && file.size < 5000000) {
        formData.append("file", file)
        this.imgPreview.push(URL.createObjectURL(file))
        this.fileList.push({ name: file.name, size: file.size })
      } else {
        await errorAlert(MSG_VALIDATE_FILE)
      }
    },
    removeImage(index) {
      let formData2 = new FormData()
      let number = 0
      for (let item of formData.entries()) {
        if (number != index) {
          formData2.append("image", item[1])
        }
      }
      formData.delete("image")
      formData = formData2
      this.imgPreview.splice(index, 1)
    },
    removeFile(index) {
      let formData2 = new FormData()
      let number = 0
      for (let item of formData.entries()) {
        if (number != index) {
          formData2.append("file", item[1])
        }
      }
      formData.delete("file")
      formData = formData2
      this.fileList.splice(index, 1)
    },
    toggleVisibleDialog() {
      this.visibleDialog = !this.visibleDialog
      formData = new FormData() 
    },
    async submit() {
      formData.append("token", this.user.token)
      formData.append("title", this.post.title)
      const { code, message } = await createPost(formData)
      this.isLoading = true
      if (code == 200) {
        successAlert(MSG_CREATE_SUCCESS)
        this.changeTab(0)
        location.reload()
      } else {
        errorAlert(message)
      }
      formData = new FormData()
      this.isLoading = false
    },
    goPage(path) {
      goPage(path)
    }
  },
  watch: {
    linkYoutube(val) {
      if (val && val.length) {
        const strWatch = "watch?v="
        formData.delete("link")
        if (val.includes(strWatch)) {
          const strSplit = val.split(strWatch)[1]
          this.post.link = strSplit.substr(0, 11)
          formData.append("link", this.post.link)
        }
      }
    },
    visibleDialog(val) {
      if(!val) {
        this.changeTab(0)
      }
    }
  }
}
</script>
