/** CONSTANTS */
import { registerTeacher } from "../../../../constants/form"
import { IMG_LOGO, IMG_FACEBOOK, IMG_GOOGLE } from "../../../../constants/media"
import { rules } from "../../../../utils/validate"
import { genders, graduations } from "../../../../constants/selections"

/** FUNCTIONS */
import { signup, socialGoogleLogin, socialFacecbookLogin } from "../../../../services/auth"
import { createBase64 } from "../../../../utils/app"

/** COMPONENTS */
import InputGroup from "../../../../components/InputGroup.vue"
import SplitDateSelect from "../../../../components/SplitDateSelect.vue"
import SelectMd from "../../../../components/SelectMd.vue"
import RadioGroup from "../../../../components/RadioGroup.vue"
import BtnUpload from "../../../../components/buttons/BtnUpload.vue"
import BtnSpecial from "../../../../components/buttons/BtnSpecial.vue"
export default {
  data: () => ({
    imgLogo: IMG_LOGO,
    imgFacebook: IMG_FACEBOOK,
    imgGoogle: IMG_GOOGLE,
    teacher: registerTeacher,
    rules: {
      required: rules.required,
      email: rules.email,
      phone: rules.phone,
      match: rules.match,
      password: rules.password,
      idCard: rules.idCard,
    },
    genders: genders,
    graduations: graduations,
    layout: {
      half: "col-12 col-sm-4 col-md-4 col-xl-3",
      full: "col-12 col-sm-8 col-md-8 col-xl-6"
    },
    isValid: false,
    isLoading: false
  }),
  components: {
    InputGroup,
    SplitDateSelect,
    SelectMd,
    RadioGroup,
    BtnUpload,
    BtnSpecial
  },
  methods: {
    socialGoogleLogin: socialGoogleLogin,
    socialFacecbookLogin: socialFacecbookLogin,
    async submit() {
      const isValid = this.$refs.registerForm.validate()
      if (isValid) {
        this.isLoading = true
        await signup(this.teacher, "teacher")
        this.isLoading = false
      }
    },
    async onchangeHandler(file) {
      const imgExt = ["application/pdf"]
      if (file.type && imgExt.includes(file.type) && file.size < 5000000) {
        const result = await createBase64(file)
        this.account.file = result.split(",")[1]
      } else {
        await errorAlert(MSG_VALIDATE_FILE)
      }
    }
  }
}
