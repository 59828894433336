<template>
  <v-card class="preview-img cover" :style="{'max-width': `${maxWidth}px`}">
    <v-btn class="icon-close white" icon small fab @click="onclickClose()" v-if="!disabledClose && img">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <slot name="tools"></slot>
    <v-img class="fill-height fill-width" :src="img" width="100%" :max-width="maxWidth" :max-height="maxHeight" v-if="img">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </v-card>
</template>

<script>
import { MSG_CONFIRM_CHANGE_DELETE } from "../constants/messages"
import { confirmAlert } from "../utils/alert"

import BtnSm from "../components/buttons/BtnSm"
export default {
  components: {
    BtnSm
  },
  props: {
    img: {
      type: String,
      default: () => ""
    },
    maxWidth: {
      type: Number,
      default: () => 700
    },
    maxHeight: {
      type: Number,
      default: () => 700
    },
    disabledClose: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    async onclickClose() {
      const isConfirm = await confirmAlert(MSG_CONFIRM_CHANGE_DELETE)
      if (isConfirm) {
        this.$emit("onclickClose", true)
      }
    }
  }
}
</script>
