import { mapState } from "vuex"

import { API_BASE_URL } from "../../../constants/config"
import { API_COMMENT_CREATE, API_POST } from "../../../constants/endpoints"
import { axiosPost } from "../../../utils/api"
import { errorAlert } from "../../../utils/alert"
import { goPage } from "../../../utils/app"

import Avatar from "../../../components/Avatar.vue"
import InputGroup from "../../../components/InputGroup.vue"
import CardPost from "../../../components/CardPost.vue"
import WrapperComment from "../../../components/wrapperMediaCard/WrapperComment.vue"
import ModalYoutubeFrame from "../../../components/modal/ModalYoutubeFrame.vue"
export default {
  data: () => ({
    apiURL: API_BASE_URL,
    course: {},
    isStarter: false,
    tab: null,
    link: null,
    title: null,
    description: null,
    visibleModal: false
  }),
  components: {
    Avatar,
    InputGroup,
    CardPost,
    WrapperComment,
    ModalYoutubeFrame
  },
  mounted() {
    this.fetchCourse()
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    async fetchCourse() {
      const { data: course, code } = await axiosPost(API_POST, {
        token: this.user.token,
        post_id: this.$route.params.id
      })

      if (code == 200) {
        this.course = course
      } else {
        await errorAlert(message)
        goPage("/")
      }
      this.isStarter = true
    },
    processSuccess() {
      this.fetchCourse()
    },
    async sendComment(e, id) {
      const {
        keyCode,
        target: { value }
      } = e
      if (keyCode == 13 && !!value.trim()) {
        const { code } = await axiosPost(API_COMMENT_CREATE, {
          token: this.user.token,
          post_id: id,
          comment: value.trim()
        })
        if (code == 200) {
          this.$refs.inputComment.value = ""
        }
      }
    },
    replySuccess() {
      this.processSuccess()
    },
    openModal(url, title = "", description = "") {
      this.visibleModal = false
      this.link = url
      this.title = title
      this.description = description
      this.visibleModal = true
    },
    closeModal(val) {
      if (!val) {
        this.visibleModal = false
        this.link = null
        this.title = null
        this.description = null
      }
    }
  }
}
