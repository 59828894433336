<template>
  <v-badge color="error" :content="count" class="d-flex" offset-y="20" offset-x="-20" :value="count !== '0'">
    <v-icon class="icon-link">mdi-bell</v-icon>
    <slot></slot>
  </v-badge>
</template>

<script>
export default {
  props: {
    count: {
      type: String
    }
  }
}
</script>
