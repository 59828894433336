<template>
  <ModalBase v-model="isActive" :maxWidth="500" :title="title">
    <template v-slot:body>
      <v-form class="py-4" v-model="valid" ref="editForm" lazy-validation>
        <CoverPreview :img="cover" @onclickClose="removeImage()">
          <template v-slot:tools>
            <BtnUpload size="sm" theme="light" absolute @onchangeHandler="onchangeHandler" v-if="!cover">
              แก้ไขรูปภาพ
            </BtnUpload>
          </template>
        </CoverPreview>
        <InputGroup v-model="name" layout="w-100" label="ชื่อกลุ่ม" :validates="[rules.required]" />
        <InputGroup v-model="description" layout="w-100" label="คำอธิบายกลุ่ม" :validates="[rules.required]" />
      </v-form>

      <v-divider class="my-4"></v-divider>
      <BtnSm class="btn-primary btn-rounded-pill" @click.native="submit()">บันทึก</BtnSm>
    </template>
  </ModalBase>
</template>

<script>
import { mapState } from "vuex"
import { API_BASE_URL } from "../../constants/config"
import { API_GROUP_UPDATE } from "../../constants/endpoints"
import { MSG_CHANGE_SUCCESS, MSG_CONFIRM_CHANGE, MSG_VALIDATE_IMAGE } from "../../constants/messages"
import { confirmAlert, errorAlert, successAlert } from "../../utils/alert"
import { rules } from "../../utils/validate"
import { axiosPost } from "../../utils/api"

import ModalBase from "./ModalBase"
import BtnSm from "../buttons/BtnSm"
import BtnUpload from "../buttons/BtnUpload"
import CoverPreview from "../CoverPreview"
import InputGroup from "../InputGroup"

let formData = new FormData()
let cache = null

export default {
  data: () => ({
    valid: false,
    rules: {
      required: rules.required
    },
    imgPreview: null,
    cover: null,
    name: null,
    description: null
  }),
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    title: {
      type: String,
      default: () => "ผู้ติดตาม"
    },
    groupId: {
      type: String
    },
    groupName: {
      type: String
    },
    groupDescription: {
      type: String
    },
    image: {
      type: String
    }
  },
  components: {
    ModalBase,
    BtnSm,
    BtnUpload,
    CoverPreview,
    InputGroup
  },
  computed: {
    ...mapState(["user"]),
    isActive: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },
  methods: {
    setDefault() {
      this.cover = `${API_BASE_URL}/${this.image}`
      cache = `${API_BASE_URL}/${this.image}`
      this.name = this.groupName
      this.description = this.groupDescription
    },
    async onchangeHandler(image) {
      formData = new FormData()
      this.cover = cache
      const imgExt = ["image/png", "image/jpg", "image/jpeg"]
      if (image.type && imgExt.includes(image.type) && image.size < 5000000) {
        formData.append("cover", image)
        this.cover = URL.createObjectURL(image)
      } else {
        await errorAlert(MSG_VALIDATE_IMAGE)
      }
    },
    async removeImage() {
      this.cover = null
      formData.delete("cover")
    },
    async submit() {
      const isValid = this.$refs.editForm.validate()
      if (isValid) {
        const isConfirm = await confirmAlert(MSG_CONFIRM_CHANGE)
        if (isConfirm) {
          formData.append("token", this.user.token)
          formData.append("user_id", this.user.userId)
          formData.append("group_id", this.groupId)
          formData.append("name", this.name)
          formData.append("description", this.description)

          const { code, message } = await axiosPost(API_GROUP_UPDATE, formData)
          if (code == 200) {
            await successAlert(MSG_CHANGE_SUCCESS)
            this.$emit("input", true)
          } else {
            await errorAlert(message)
          }
        }
      }
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.setDefault()
      }
    }
  }
}
</script>
