<template>
  <div class="d-flex">
    <SidebarSm />
    <v-main class="wrapper-main wrapper-edit">
      <div class="main-container">
        <v-container class="mt-4 mt-sm-10">
          <ToolbarSearch class="mb-10" />
          <router-view />
        </v-container>
      </div>
    </v-main>
    <ToolbarBottomResponsive />
  </div>
</template>

<script>
import SidebarSm from "../../components/SideBarSm"
import ToolbarSearch from "../../components/toolbars/ToolbarSearch"
import ToolbarBottomResponsive from "../../components/toolbars/ToolbarBottomResponsive"
export default {
  components: {
    SidebarSm, ToolbarSearch, ToolbarBottomResponsive 
  }
}
</script>