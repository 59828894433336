/** CONSTANTS */
import { IMG_LOGO, IMG_FACEBOOK, IMG_GOOGLE } from "../../../constants/media"
import { loginForm } from "../../../constants/form"
import { rules } from "../../../utils/validate"

/** AUTH FUNCTION */
import { login, socialGoogleLogin, socialFacecbookLogin, forgetPassword } from "../../../services/auth"

/** COMPONENTS */
import InputGroup from "../../../components/InputGroup.vue"
import BtnSpecial from "../../../components/buttons/BtnSpecial.vue"
import CheckBox from "../../../components/CheckBox.vue"
import ModalForgetPassword from "../../../components/modal/ModalForgetPassword.vue"
import ToolbarAuth from "../../../components/toolbars/ToolbarAuth"
import { errorAlert, successAlert } from "../../../utils/alert"
export default {
  data: () => ({
    imgLogo: IMG_LOGO,
    imgFacebook: IMG_FACEBOOK,
    imgGoogle: IMG_GOOGLE,
    account: loginForm,
    rules: {
      required: rules.required,
      email: rules.email,
    },
    remember: false,
    isLoading: false,
    isShow: false,
    isSuccess: false,
    isValid: false,
    visibleModal: false,
    layout: "col-12 col-sm-8 col-md-8"
  }),
  components: {
    InputGroup,
    BtnSpecial,
    CheckBox,
    ModalForgetPassword,
    ToolbarAuth
  },
  methods: {
    async submit() {
      const isValid = this.$refs.loginForm.validate()
      if (isValid) {
        this.isLoading = true
        await login(this.account)
        this.isLoading = false
      }
    },
    async sendEmail(email) {
      if (email) {
        this.isLoading = true
        const { code, message } = await forgetPassword(email)
        if (code == 200) {
          await successAlert(message)
        } else {
          await errorAlert(message)
        }
        this.isLoading = false
        this.visibleModal = false
      }
    },
    socialGoogleLogin: socialGoogleLogin,
    socialFacecbookLogin: socialFacecbookLogin
  }
}
