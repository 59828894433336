<template>
  <div class="btn-rounded-pill btn-tag-subject">
    <slot>Tag</slot>
  </div>
</template>

<script>
import BtnSm from "./buttons/BtnSm"
export default {
  components: {
    BtnSm
  }
}
</script>
