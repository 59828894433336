import { mapState } from "vuex"

import { API_BASE_URL } from "../../constants/config"
import { API_GROUP_FIND, API_GROUP_LEAVE } from "../../constants/endpoints"
import { goPage } from "../../utils/app"
import { axiosPost } from "../../utils/api"
import { confirmAlert, errorAlert, successAlert } from "../../utils/alert"

import Avatar from "../../components/Avatar.vue"
import BtnSm from "../../components/buttons/BtnSm.vue"
import BtnMd from "../../components/buttons/BtnMd.vue"
import BtnLg from "../../components/buttons/BtnLg.vue"
import BtnFollow from "../../components/buttons/BtnFollow.vue"
import ToolbarProfile from "../../components/toolbars/ToolbarProfile.vue"
import ModalFollow from "../../components/modal/ModalFollow.vue"
import ModalCover from "../../components/modal/ModalCover.vue"

export default {
  data: () => ({
    apiURL: API_BASE_URL,
    group: {},
    followList: [],
    isModal: false,
    titleCard: null,
    isStarter: false,
    isActive: false,
    title: "ผู้ติดตาม"
  }),
  components: {
    Avatar,
    BtnSm,
    BtnMd,
    BtnLg,
    BtnFollow,
    ToolbarProfile,
    ModalFollow,
    ModalCover
  },
  mounted() {
    this.fetchGroup()
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    async fetchGroup() {
      const id = this.$route.params.id
      if (id) {
        const { data: group, code, message } = await axiosPost(API_GROUP_FIND, {
          token: this.user.token,
          group_id: id
        })
        if (code == 200) {
          this.group = group
          this.isStarter = true
        } else {
          await errorAlert(message)
          goPage('/group')
        }
      }
    },
    goPage(path) {
      goPage(path)
    },
    async leaveGroup() {
      const isConfirm = await confirmAlert("ยืนยันการออกตจากกลุ่ม")
      if (isConfirm) {
        const { code, message } = await axiosPost(API_GROUP_LEAVE, {
          token: this.user.token,
          group_id: this.group.group_id,
          user_id: this.user.userId,
          member_type: this.group.status
        })
        if (code == 200) {
          await successAlert("ออกจากกลุ่มสำเร็จ !")
          goPage("/group")
        }
        else {
          await errorAlert(message)
        }
      }
    },
    closeModal(val) {
      if (val) {
        this.fetchGroup()
      }
      this.isModal = false
    }
  },
  watch: {
    // $route() {
    //   this.fetchGroup()
    // }
  }
}
