<template>
  <div class="d-flex">
    <SidebarSm />
    <ToolbarTopResponsive class="d-block d-sm-none" />
    <v-main class="wrapper-main wrapper-edit">
      <div class="main-container">
        <v-container>
          <ToolbarSearch class="d-none d-sm-flex mb-10" />
          <router-view />
        </v-container>
      </div>
    </v-main>
    <ToolbarBottomResponsive />
  </div>
</template>

<script>
import SidebarSm from "../../components/SideBarSm"
import ToolbarSearch from "../../components/toolbars/ToolbarSearch"
import ToolbarTopResponsive from "../../components/toolbars/ToolbarTopResponsive"
import ToolbarBottomResponsive from "../../components/toolbars/ToolbarBottomResponsive"

export default {
  components: {
    SidebarSm, ToolbarSearch, ToolbarTopResponsive, ToolbarBottomResponsive
  }
}
</script>