import firebase from "firebase"
import store from "../store"

import { axiosPost } from "../utils/api"
import { goPage } from "../utils/app"
import { enableNotification } from "../utils/onesignal"
import { errorAlert } from "../utils/alert"

/**
 * CONSTANTS (MESSAGE | ENDPOINT)
 */
import {
  API_LOGIN,
  API_LOGIN_SOCIAL,
  API_FORGET_PASSWORD,
  API_CHANGE_PASSWORD,
  API_STUDENT_CREATE,
  API_TEACHER_CREATE
} from "../constants/endpoints"
import { MSG_LOGIN_FAILED } from "../constants/messages"

const login = async (account) => {
  const { data: user, code, message } = await axiosPost(API_LOGIN, account)
  if (code !== 200) {
    await errorAlert(message)
    return
  }
  store.commit("SET_AUTH", user)
  if (store.getters.doneAuth) {
    enableNotification(user.user_id)
    goPage("/")
  }
}

const signup = async (user, type) => {
  let endpoint = API_STUDENT_CREATE
  if (type == "teacher") {
    endpoint = API_TEACHER_CREATE
  }
  const { data, code, message } = await axiosPost(endpoint, user)
  if (code !== 200) {
    await errorAlert(message)
    return
  }
  store.commit("SET_AUTH", data)
  if (store.getters.doneAuth) {
    enableNotification(data.user_id)
    goPage("/")
  }
}

const loginSocial = async (profile) => {
  const { data: user, code, message } = await axiosPost(API_LOGIN_SOCIAL, { ...profile })
  if (code !== 200) {
    await errorAlert(message)
    return
  }
  store.commit("SET_AUTH", user)
  if (store.getters.doneAuth) {
    enableNotification(user.user_id)
    goPage("/")
  }
}

const socialGoogleLogin = async () => {
  const provide = new firebase.auth.GoogleAuthProvider().addScope("email")
  firebase
    .auth()
    .signInWithPopup(provide)
    .then((result) => {
      const { given_name, family_name, email, picture } = result.additionalUserInfo.profile

      let obj = {
        firstname: given_name,
        lastname: family_name,
        email: email,
        img: picture,
        register_by: "google"
      }
      loginSocial(obj)
    })
    .catch((err) => {
      errorAlert(MSG_LOGIN_FAILED)
    })
}

const socialFacecbookLogin = async () => {
  const provide = new firebase.auth.FacebookAuthProvider()
  const {
    additionalUserInfo: {
      profile: { first_name, last_name, email }
    },
    user: { photoURL }
  } = await firebase.auth().signInWithPopup(provide)
  let obj = {
    firstname: first_name,
    lastname: last_name,
    email: email,
    img: photoURL + "?width=500&height=500",
    register_by: "facebook"
  }
  await loginSocial(obj)

}

const forgetPassword = async (email) => {
  const { code, message } = await axiosPost(API_FORGET_PASSWORD, {email: email})
  return { code, message }
}

const changePassword = async (account) => {
  const { password, password_confirm } = account
  return await axiosPost(API_CHANGE_PASSWORD, {
    user_id: store.state.user.userId,
    password,
    password_confirm,
    token: store.state.user.token
  })
}

export { login, signup, socialGoogleLogin, socialFacecbookLogin, forgetPassword, changePassword }
