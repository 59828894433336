<template>
  <v-app-bar class="py-0 px-0 px-sm-2" fixed top app color="rgb(255, 255, 255)" height="60px">
    <v-img class="pointer" :src="imgLogo" width="40px" @click.native="goPage('/')"></v-img>
    <v-toolbar-items class="align-self-center justify-space-between mx-2 mx-sm-10" style="width: 100%">
      <ToolbarSearch class="middle" />
    </v-toolbar-items>
    <v-toolbar-items class="align-self-center justify-space-between" style="width: 20%">
      <Avatar
        class="pointer d-none d-md-flex"
        :image="user.img"
        @click.native="goPage(`/profile/timeline/${user.userId}`)"
      />
      <v-list-item-content class="pointer d-none d-md-flex nowrap" @click="logout()">
        <a>ออกจากระบบ</a>
      </v-list-item-content>
      <v-list-item-content class="pointer d-flex d-md-none">
        <v-menu offset-y class="d-flex d-md-none">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small rounded>
              <v-icon v-bind="attrs" v-on="on">mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <Avatar class="pointer" :image="user.img" @click.native="goPage(`/profile/timeline/${user.userId}`)" />
              <v-list-item-title @click="goPage(`/profile/timeline/${user.userId}`)">
                {{ user.fullname }}
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="openCreateModal()">
              <v-icon class="mr-2"> mdi-pencil </v-icon>
              <p>เพิ่มโพสต์</p>
            </v-list-item>
            <v-list-item to="/course/create">
              <img :src="iconCourse" class="mr-2" width="26px">
              <p>สร้างคอร์ส</p>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item to="/course">
              <v-icon class="mr-2">mdi-book-open-page-variant-outline</v-icon>
              <p class="text-link">คอร์สทั้งหมด</p>
            </v-list-item>
            <v-list-item to="/group">
              <v-icon class="mr-2">mdi-account-group</v-icon>
              <p class="text-link">กลุ่มทั้งหมด</p>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item to="#">
              <v-list-item-icon class="mr-2">
                <v-icon> mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-title>ตั้งค่า</v-list-item-title>
            </v-list-item>
            <v-list-item to="#">
              <v-list-item-icon class="mr-2">
                <v-icon> mdi-help-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-title>ช่วยเหลือ</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-icon class="mr-2">
                <v-icon> mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title @click="logout()">ออกจากระบบ</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-content>
    </v-toolbar-items>
    <ModalCreatePost v-model="visibleDialog" />
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex"
import { IMG_LOGO, ICON_COURSE_GREY } from "../../constants/media"
import { goPage, logout } from "../../utils/app"

import Avatar from "../Avatar"
import ToolbarSearch from "../toolbars/ToolbarSearch"
import ModalCreatePost from "../modal/ModalCreatePost"

export default {
  data: () => ({
    imgLogo: IMG_LOGO,
    iconCourse: ICON_COURSE_GREY,
    visibleDialog: false
  }),
  components: {
    Avatar,
    ToolbarSearch,
    ModalCreatePost
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    logout: logout,
    goPage(page) {
      goPage(page)
    },
    openCreateModal() {
      this.visibleDialog = true
    }
  }
}
</script>
