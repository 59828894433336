<template>
  <div class="sidebar-recommended">
    <div class="wrapper-overflow">
      <v-card class="wrapper-recommended mb-4">
        <v-card-title> แนะนำผู้สอน </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-2">
          <v-list class="mb-8">
            <v-list-item class="px-0" v-for="(item, index) in recommendedTeacher" :key="`item-${index}`">
              <Avatar :image="item.img" :link="`/profile/timeline/${item.user_id}`" />
              <v-list-item-content class="text-start" @click="$router.push(`/profile/timeline/${item.user_id}`)">
                <a>
                  <p>{{ item.fullname }}</p>
                </a>
              </v-list-item-content>
              <v-list-item-action>
                <BtnFollow
                  :isActive="item.user_id !== user.userId && item.is_follow"
                  @click.native="onFollow(item.is_follow, item.user_id)"
                />
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
      <v-card class="wrapper-recommended">
        <v-card-title> โพสต์ยอดนิยม </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-2">
          <v-list class="mb-8">
            <v-list-item class="px-0" v-for="(item, index) in topPost" :key="`item-${index}`">
              <Avatar :image="item.img" :link="`/profile/timeline/${item.post_id}`" />
              <v-list-item-content class="text-start" @click="goPage(`/course/view/${item.post_id}/`)">
                <a>
                  <p class="mb-1">{{ item.caption }}</p>
                  <p>{{ item.fullname }}</p>
                </a>
              </v-list-item-content>
              <v-list-item-content>
                <TagSubject>{{ item.subject }}</TagSubject>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import { API_NAV } from "../constants/endpoints"
import { MSG_CONFIRM_UNFOLLOW } from "../constants/messages"
import { axiosPost } from "../utils/api"
import { confirmAlert } from "../utils/alert"
import { follow } from "../services/follow"
import { goPage } from "../utils/app"

import Avatar from "./Avatar"
import BtnFollow from "./buttons/BtnFollow"
import TagSubject from "./TagSubject"
export default {
  data: () => ({
    recommendedTeacher: [],
    topPost: [],
    test: ""
  }),
  components: {
    Avatar,
    BtnFollow,
    TagSubject
  },
  mounted() {
    this.fetchRecommended()
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["doneAuth"])
  },
  methods: {
    async fetchRecommended() {
      const {
        data: { teacher_recommend, post_recommend },
        code
      } = await axiosPost(API_NAV, { token: this.user.token })
      if (code == 200) {
        if (teacher_recommend.length && teacher_recommend.length) {
          this.recommendedTeacher = teacher_recommend
          this.topPost = post_recommend
        }
      }
    },
    async onFollow(currentStatus, id) {
      if (currentStatus) {
        const isConfirm = confirmAlert(MSG_CONFIRM_UNFOLLOW)
        if (!isConfirm) return
      }
      await follow(currentStatus, id)
      this.fetchRecommended()
    },
    goPage(page) {
      goPage(page)
    }
  }
}
</script>
