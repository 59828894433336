import { getCurrent } from "../utils/app"

const registerStudent = {
  firstname: null,
  lastname: null,
  username: null,
  password: null,
  confirm_password: null,
  date_of_birth: getCurrent().date,
  sex: 3,
  email: null,
  tel: null,
  faculty: null,
  university: null,
  id_card: null,
  register_by: "website"
}

const registerTeacher = {
  firstname: null,
  lastname: null,
  username: null,
  password: null,
  confirm_password: null,
  date_of_birth: getCurrent().date,
  sex: 3,
  email: null,
  tel: null,
  educaiton: null,
  university: null,
  file: null,
  file_type: null,
  id_card: null,
  register_by: "website"
}

const loginForm = {
  /* username: "teacher.bas",
  password: "teacher.bas123" */
  /* username: "teacher.bas@mail.com",
  password: "Admin123" */
  /* username: "teacher.arm@mail.com",
  password: "arm123456789" */
  username: null,
  password: null
}

const passwordChangeForm = {
  password: null,
  password_confirm: null
}

const passwordReset = {
  user_id: null,
  password: null,
  confirm_password: null
}

const postForm = {
  title: null,
  file: null,
  file_type: null,
  link: null
}

const courseForm = {
  /* caption: "หลักสูตรรวบรัก ภายใน 12 ชม",
  title: "หลักสูตรรวบรัด",
  subject: "FAST",
  course_id: "FAST01",
  description: "เรียนไว จบง่าย ภายใน 12 ชม",
  privacy: "public", */
  caption: null,
  title: null,
  subject: null,
  course_id: null,
  description: null,
  privacy: "public",
  file_cover: [],
  file_cover_type: [],
  chapter: []
}

const groupForm = {
  name: null,
  description: null
}

export { registerStudent, registerTeacher, loginForm, passwordChangeForm, passwordReset, postForm, courseForm, groupForm }
