<template>
  <v-app-bar class="pt-0 pb-0 px-0 pr-2 bottom-bar" fixed bottom app color="rgb(255, 255, 255)" height="60px">
    <v-toolbar-items class="align-self-center justify-space-between" style="width: 100%">
      <router-link to="/" class="d-flex" exact>
        <v-icon class="icon-link">mdi-home</v-icon>
      </router-link>
      <router-link to="/notification" class="mt-auto mb-auto">
        <v-badge
          color="error"
          :content="countUnread"
          class="d-flex mx-auto"
          offset-y="15"
          offset-x="12"
          :value="countUnread !== '0'"
        >
          <v-icon class="icon-link">mdi-bell</v-icon>
        </v-badge>
      </router-link>
      <router-link to="/saved" class="d-flex">
        <v-icon class="icon-link">mdi-bookmark</v-icon>
      </router-link>
      <!-- <router-link to="/message" class="mt-auto mb-auto"> -->
      <router-link to="#" class="mt-auto mb-auto">
        <!-- <v-badge color="error" content="6" class="d-flex" offset-y="15" offset-x="12"> -->
        <v-icon class="icon-link">mdi-chat</v-icon>
        <!-- </v-badge> -->
      </router-link>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { API_NOTIFICATION_COUNT } from "../../constants/endpoints"
import { axiosPost } from "../../utils/api"
export default {
  data: () => ({
    countUnread: "0"
  }),
  computed: {
    ...mapState(["user"])
  },
  methods: {
    ...mapActions(["fetchNotiCount"]),
    async countNoti() {
      const { data: count, code } = await axiosPost(API_NOTIFICATION_COUNT, { token: this.user.token })
      if (count && code == 200) {
        this.countUnread = data.toString()
      }
    }
  },
  destroyed() {
    this.fetchNotiCount()
  }
}
</script>
