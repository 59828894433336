import { mapState } from "vuex"
import { API_POST } from "../../../constants/endpoints"
import { axiosPost } from "../../../utils/api"
import { errorAlert } from "../../../utils/alert"

import CardPost from "../../../components/CardPost.vue"
import { goPage } from "../../../utils/app"
export default {
    data: () => ({
        post: {},
        isStarter: false
    }),
    components: {
        CardPost
    },
    mounted() {
        this.fetchPost()
    },
    computed: {
        ...mapState(["user"])
    },
    methods: {
        async fetchPost() {
            const { data: post, code, message } = await axiosPost(API_POST, {
                token: this.user.token,
                post_id: this.$route.params.id
            })

            if (code == 200) {
                this.post = post
            } else {
                await errorAlert(message)
                goPage("/")
            }
            this.isStarter = true
        },
        processSuccess() {
            this.fetchPost()
        }
    }
}