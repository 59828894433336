import { mapState } from "vuex"
import { API_GROUP_FIND_MEMBER } from "../../../constants/endpoints"
import { axiosPost } from "../../../utils/api"

import Avatar from "../../../components/Avatar.vue"
import ModalMember from "../../../components/modal/ModalMember.vue"
export default {
	data: () => ({
		admins: [],
		coleaders: [],
		members: [],
		id: null,
		type: null,
		fullname: null,
		img: null,
		visibleModal: false,
		status: null
	}),
	mounted() {
		this.fetchMember()
	},
	components: {
		Avatar,
		ModalMember
	},
	computed: {
		...mapState(["user"])
	},
	methods: {
		async fetchMember() {
			const { data: users } = await axiosPost(API_GROUP_FIND_MEMBER, { token: this.user.token, group_id: this.$route.params.id })
			const { admin, coleaders, members, status } = users
			this.admins = admin
			this.coleaders = coleaders
			this.members = members
			this.status = status
		},
		openModal(id, type, img, fullname) {
			this.id = id
			this.type = type
			this.img = img
			this.fullname = fullname
			this.visibleModal = true
		},
		closeModal(val) {
			if (!val) {
				this.visibleModal = false
				this.fetchMember()
			}
		}
	}
}