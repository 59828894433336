<template>
  <div class="wrapper-frame">
    <iframe
      width="100%"
      height="100%"
      :src="`https://www.youtube.com/embed/${link}`"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String
    }
  }
}
</script>