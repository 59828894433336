import { rules } from "../../../../utils/validate"
import { changePassword } from "../../../../services/auth"
import { passwordChangeForm } from "../../../../constants/form"
import { confirmAlert, errorAlert, successAlert } from "../../../../utils/alert"

import InputGroup from "../../../../components/InputGroup"
import BtnSm from "../../../../components/buttons/BtnSm"
import { MSG_CONFIRM_CHANGE } from "../../../../constants/messages"

export default {
  data: () => ({
    isValid: false,
    account: passwordChangeForm,
    rules: {
      required: rules.required,
      password: rules.password,
      match: rules.match
    },
    layout: "col-12 col-sm-6 col-md-6",
    isLoading: false
  }),
  components: {
    InputGroup,
    BtnSm
  },
  methods: {
    async submit() {
      const isValid = this.$refs.passwordForm.validate()
      if (isValid) {
        const isConfirm = await confirmAlert(MSG_CONFIRM_CHANGE + "รหัสผ่าน?")
        if (isConfirm) {
          const isValid = this.$refs.passwordForm.validate()
          if (isValid) {
            this.isLoading = true
            const { code, message } = await changePassword(this.account)
            if (code !== 200) {
              await errorAlert(message)
            } else {
              await successAlert(message)
            }
            ; (this.account.password = null), (this.account.password_confirm = null), (this.isLoading = false)
          }
        }
      }
    }
  }
}
