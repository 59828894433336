<template>
  <div class="d-flex">
    <SideBar />
    <ToolbarTopResponsive class="d-block d-sm-none" />
    <v-main class="wrapper-main">
      <div class="main-container">
        <v-container>
          <ToolbarSearch class="d-none d-sm-flex mb-10" />
          <router-view />
        </v-container>
        <RecommendedSide />
      </div>
    </v-main>
    <ToolbarBottomResponsive />
  </div>
</template>

<script>
import SideBar from "../components/SideBar"
import RecommendedSide from "../components/RecommendedSide"
import ToolbarTopResponsive from "../components/toolbars/ToolbarTopResponsive"
import ToolbarSearch from "../components/toolbars/ToolbarSearch"
import ToolbarBottomResponsive from "../components/toolbars/ToolbarBottomResponsive"
export default {
  components: {
    SideBar,
    RecommendedSide,
    ToolbarSearch,
    ToolbarBottomResponsive,
    ToolbarTopResponsive
  }
}
</script>