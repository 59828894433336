<template>
  <v-list-group class="text-center w-100" :ripple="false" v-if="comments.length">
    <template v-slot:activator>
      <p class="w-100 mx-auto">อ่านทั้งหมด <span></span></p>
    </template>
    <v-list v-for="(comment, index) in comments" :key="`comment-${index}`" dense>
      <ListComment
        :avatar="comment.img"
        :fullname="comment.fullname"
        :username="comment.username"
        :comment="comment.comment"
        :userId="comment.user_id"
      />
      <v-list dense class="pl-8">
        <WrapperSubComment :reply="comment.reply" :userId="userId" />
        <v-list-item class="pl-6 pl-sm-10">
          <Avatar
            :image="user.img"
            color="white"
            removeMargin
            @click.native="goPage(`/profile/timeline/${user.userId}`)"
          />
          <v-list-item-content>
            <InputGroup
              class="input-comment w-100"
              placeholder="เขียนข้อความ"
              layout="col-auto"
              color="primary"
              @keydown.native="sendReply($event, comment.comment_id, index)"
              ref="inputSubComment"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-list>
  </v-list-group>
</template>

<script>
import { mapState } from "vuex"
import { API_SUB_COMMENT_CREATE } from "../../constants/endpoints"
import { axiosPost } from "../../utils/api"
import { goPage } from "../../utils/app"
import Avatar from "../Avatar"
import InputGroup from "../InputGroup"
import WrapperSubComment from "../wrapperMediaCard/WrapperSubComment"
import ListComment from "../ListComment"
export default {
  props: {
    postId: {
      type: String
    },
    comments: {
      type: Array,
      default: () => []
    },
    userId: {
      type: String
    }
  },
  components: {
    Avatar,
    InputGroup,
    WrapperSubComment,
    ListComment
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    goPage(page) {
      goPage(page)
    },
    async sendReply(e, id, i) {
      const {
        keyCode,
        target: { value }
      } = e
      if (keyCode == 13 && !!value.trim()) {
        const { code } = await axiosPost(API_SUB_COMMENT_CREATE, {
          token: this.user.token,
          post_id: this.postId,
          comment_id: id,
          comment: value.trim()
        })
        if (code == 200) {
          this.$refs.inputSubComment[i].value = ""
          this.$emit("replySuccess", true)
        }
      }
    }
  }
}
</script>
