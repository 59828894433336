<template>
  <v-dialog v-model="isActive" :max-width="`${maxWidth}px`" :fullscreen="$vuetify.breakpoint.xs">
    <v-card class="mb-4 text-end elevation-0 card-close d-sm-flex d-none">
      <v-btn icon class="ml-auto white--text transpaernt" @click="isActive = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card>
    <v-card class="elevation-0 card-post pa-0">
      <v-card-text class="pa-0 position-relative">
        <v-btn icon fab absolute right rounded @click="isActive = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <slot name="body"></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    maxWidth: {
      type: Number,
      default: () => 1000
    },
    title: {
      type: String
    }
  },
  computed: {
    isActive: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  }
}
</script>
