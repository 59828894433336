const genders = [
  { text: "ชาย", value: 1 },
  { text: "หญิง", value: 2 },
  { text: "อื่นๆ", value: 3 }
]

const graduations = ["ปวส", "ปริญญาตรี", "ปริญญาโท", "ปริญญาเอก"]

const statusCourse = [
  { status: "public", text: "สาธารณะ" },
  { status: "private", text: "เฉพาะฉัน" }
]

export { genders, graduations, statusCourse }
