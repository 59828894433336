import { mapState } from "vuex"

import { API_BASE_URL } from "../../../constants/config"
import { postTab } from "../../../constants/app"
import { API_POST } from "../../../constants/endpoints"
import { ICON_UPLOAD, ICON_POST, ICON_CLIP } from "../../../constants/media"
import { postForm } from "../../../constants/form"
import { updatePost } from "../../../services/post"
import { rules } from "../../../utils/validate"
import { axiosPost } from "../../../utils/api"
import { confirmAlert, errorAlert, successAlert } from "../../../utils/alert"
import { MSG_CONFIRM_CHANGE, MSG_UPDATE_SUCCESS, MSG_VALIDATE_FILE } from "../../../constants/messages"

import { goPage } from "../../../utils/app"

import Avatar from "../../../components/Avatar"
import TextArea from "../../../components/TextArea"
import InputGroup from "../../../components/InputGroup"
import BtnSm from "../../../components/buttons/BtnSm"
import ImgPreview from "../../../components/ImgPreview"
import BoxUpload from "../../../components/BoxUpload"

let formData = new FormData()

export default {
  data: () => ({
    apiURL: API_BASE_URL,
    iconUpload: ICON_UPLOAD,
    iconPost: ICON_POST,
    iconClip: ICON_CLIP,
    tab: 0,
    title: postTab[0],
    post: postForm,
    imgPreview: [],
    fileRemoved: [],
    fileList: [],
    linkYoutube: "",
    file: {},
    form: [
      {
        valid: false,
        ref: "from1"
      },
      {
        valid: false,
        ref: "from2"
      },
      {
        valid: false,
        ref: "from3"
      },
      {
        valid: false,
        ref: "from4"
      }
    ],
    rules: {
      required: rules.required
    },
    isLoading: false,
    isStarter: false
  }),
  components: {
    Avatar,
    TextArea,
    InputGroup,
    BtnSm,
    ImgPreview,
    BoxUpload
  },
  props: {
    value: {
      type: Boolean,
      default: () => false
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.fetchPost()
    } else {
      goPage("/")
    }
  },
  computed: {
    ...mapState(["user"]),
    isActive: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },
  methods: {
    async fetchPost() {
      const id = this.$route.params.id
      const { data: post, code } = await axiosPost(API_POST, { token: this.user.token, post_id: id })
      if (code == 200) {
        this.post = post
        if (this.post.file_type == "img") {
          this.tab = 1
        } else if (this.post.file_type == "pdf") {
          this.tab = 3
        } else {
          if (this.post.link) {
            this.tab = 2
            this.linkYoutube = "https://www.youtube.com/watch?v=" + this.post.link
          } else {
            this.tab = 0
          }
        }
      }
      this.isStarter = true
    },
    async onchangeHandlerImg(image) {
      const imgExt = ["image/png", "image/jpg", "image/jpeg"]
      if (image.type && imgExt.includes(image.type) && image.size < 5000000) {
        formData.append("image", image)
        this.imgPreview.push(URL.createObjectURL(image))
      } else {
        await errorAlert(MSG_VALIDATE_FILE)
      }
    },
    async onchangeHandlerFile(file) {
      const imgExt = ["application/pdf"]
      if (file.type && imgExt.includes(file.type) && file.size && file.size < 5000000) {
        formData.append("file", file)
        this.imgPreview.push(URL.createObjectURL(file))
        this.fileList.push({ name: file.name, size: file.size })
      } else {
        await errorAlert(MSG_VALIDATE_FILE)
      }
    },
    removeOldFile(id) {
      const index = this.post.files.findIndex((file) => id == file.file._id)
      if (index > -1) {
        this.post.files.splice(index, 1)
        this.fileRemoved.push(id)
      }
    },
    removeImage(index) {
      let formData2 = new FormData()
      let number = 0
      for (let item of formData.entries()) {
        if (number != index) {
          formData2.append("image", item[1])
        }
      }
      formData.delete("image")
      formData = formData2
      this.imgPreview.splice(index, 1)
    },
    removeFile(index) {
      let formData2 = new FormData()
      let number = 0
      for (let item of formData.entries()) {
        if (number != index) {
          formData2.append("file", item[1])
        }
      }
      formData.delete("file")
      formData = formData2
      this.fileList.splice(index, 1)
    },
    toggleVisibleDialog() {
      this.visibleDialog = !this.visibleDialog
      formData = new FormData()
    },
    async submit() {
      const isConfirm = await confirmAlert(MSG_CONFIRM_CHANGE)
      if (isConfirm) {
        formData.append("token", this.user.token)
        formData.append("title", this.post.title)
        formData.append("post_id", this.$route.params.id)
        formData.append("del_files", this.fileRemoved)
        if (this.post.link) {
          formData.append("link", this.post.link)
        }

        const { code, message } = await updatePost(formData)
        this.isLoading = true
        if (code == 200) {
          successAlert(MSG_UPDATE_SUCCESS)
        } else {
          errorAlert(message)
        }
        formData = new FormData()
        this.isLoading = false
        location.reload()
      }
    },
    async cancleEdit() {
      const isConfirm =  await confirmAlert("ต้องการละทิ้งการแก้ไขหรือไม่")
      if (isConfirm) {
        goPage("/")
      }
    },
    goPage(path) {
      goPage(path)
    }
  },
  watch: {
    linkYoutube(val) {
      if (val && val.length) {
        const strWatch = "watch?v="
        if (val.includes(strWatch)) {
          const strSplit = val.split(strWatch)[1]
          this.post.link = strSplit.substr(0, 11)
        }
      }
    }
  }
}
