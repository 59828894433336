import { mapState } from "vuex"

import { API_GROUP_FIND_MEMBER_JOIN, API_GROUP_INVITE, API_GROUP_SEARCH_USER, API_GROUP_ACCEPT, API_GROUP_REJECT } from "../../../constants/endpoints"
import {
    MSG_CONFIRM_JOIN, MSG_CONFIRM_ADD, MSG_ADD_SUCCESS, MSG_CONFIRM_REJECT, MSG_REJECT_SUCCESS
} from "../../../constants/messages"
import { confirmAlert, errorAlert, successAlert } from "../../../utils/alert"
import { axiosPost } from "../../../utils/api"

import Avatar from "../../../components/Avatar.vue"
import BtnXs from "../../../components/buttons/BtnXs.vue"
import BtnSm from "../../../components/buttons/BtnSm.vue"
import BtnInvite from "../../../components/buttons/BtnInvite.vue"
import InputGroup from "../../../components/InputGroup.vue"
import ToolbarSearch from "../../../components/toolbars/ToolbarSearch.vue"
export default {
    data: () => ({
        invited: [],
        requests: [],
        users: [],
        search: "",
        isLoading: false,
        status: null
    }),
    mounted() {
        this.fetchUser()
    },
    components: {
        Avatar,
        BtnXs,
        BtnSm,
        BtnInvite,
        InputGroup,
        ToolbarSearch
    },
    computed: {
        ...mapState(["user"])
    },
    methods: {
        async fetchUser() {
            this.invited = []
            const { data: users } = await axiosPost(API_GROUP_FIND_MEMBER_JOIN, {
                token: this.user.token,
                group_id: this.$route.params.id
            })
            const { join, status } = users
            this.invited = join
            this.status= status
        },
        async searchUser() {
            if (this.search) {
                this.users = []
                this.isLoading = true
                const { data: users, code } = await axiosPost(API_GROUP_SEARCH_USER, { token: this.user.token, search: this.search })
                if (users.length) {
                    this.users = users
                }
                this.isLoading = false
            }
        },
        async join(id) {
            const isConfirm = await confirmAlert(MSG_CONFIRM_JOIN)
            if (isConfirm) {
                const { code, message } = await axiosPost(API_GROUP_INVITE, {
                    token: this.user.token,
                    user_id: id,
                    group_id: this.$route.params.id
                })
                if (code == 200) {
                    await successAlert(message)
                    this.fetchUser()
                } else {
                    await errorAlert(message)
                }
            }
        },
        async accept(id, type) {
            const isConfirm = await confirmAlert(MSG_CONFIRM_ADD)
            if (isConfirm) {
                const { code, message } = await axiosPost(API_GROUP_ACCEPT, {
                    token: this.user.token,
                    group_id: this.$route.params.id,
                    user_id: id,
                    member_type: type
                })
                if (code == 200) {
                    await successAlert(MSG_ADD_SUCCESS)
                    this.fetchUser()
                } else {
                    await errorAlert(message)
                }
            }
        },
        async reject(id, type) {
            const isConfirm = await confirmAlert(MSG_CONFIRM_REJECT)
            if (isConfirm) {
                const { code, message } = await axiosPost(API_GROUP_REJECT, {
                    token: this.user.token,
                    group_id: this.$route.params.id,
                    user_id: id,
                    member_type: type
                })
                if (code == 200) {
                    await successAlert(MSG_REJECT_SUCCESS)
                    this.fetchUser()
                } else {
                    await errorAlert(message)
                }
            }
        }
    }
}