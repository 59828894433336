<template>
  <v-btn class="btn-sm btn-follow btn-rounded-pill btn-primary" :disabled="!!isActive">
    <v-icon class="mr-1">{{isActive ? "mdi-email-check" : "mdi-email-plus"}}</v-icon>
    <p>{{ isActive ? "เชิญแล้ว" : "เชิญ" }}</p>
  </v-btn>
</template>

<script>
import BtnSm from "../buttons/BtnSm"
export default {
  components: {
    BtnSm
  },
  props: {
    isActive: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>
