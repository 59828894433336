<template>
  <v-select
    v-model="input"
    :class="`input-${inputStyle}`"
    :items="items"
    :item-text="itemText ? item[itemText] : ''"
    :item-value="itemValue ? item[itemValue] : ''"
    :return-object="returnObj"
    :rules="validates"
    hide-details="auto"
    solo
    outlined
    dense
    :color="color"
  ></v-select>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Array]
    },
    items: {
      type: Array
    },
    validates: {
      type: Array,
      default: () => []
    },
    returnObj: {
      type: Boolean,
      default: () => false
    },
    inputStyle: {
      type: String,
      default: () => "pill"
    },
    itemText: {
      type: String
    },
    itemValue: {
      type: String
    },
    color: {
      type: String,
      default: () => "secondary"
    }
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  }
}
</script>
