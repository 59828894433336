import { API_FOLLOW, API_FOLLOWER_LIST, API_FOLLOWING_LIST, API_UNFOLLOW } from "../constants/endpoints"
import { MSG_FOLLOW_SUCCESS, MSG_UNFOLLOW_SUCCESS } from "../constants/messages"
import store from "../store"
import { errorAlert, successAlert } from "../utils/alert"
import { axiosPost } from "../utils/api"

const selectMethodEndpoint = (status) => {
  if (status) {
    return API_UNFOLLOW
  } else {
    return API_FOLLOW
  }
}

const selectTypeEndpoint = (type) => {
  if (type == "follower") {
    return API_FOLLOWER_LIST
  } else {
    return API_FOLLOWING_LIST
  }
}

const follow = async (status, follower) => {
  const { code, message } = await axiosPost(selectMethodEndpoint(status), {
    token: store.state.user.token,
    following_user_id: store.state.user.userId,
    follower_user_id: follower
  })
  if (code == 200) {
    await successAlert(`${status ? MSG_UNFOLLOW_SUCCESS : MSG_FOLLOW_SUCCESS}`)
  } else {
    await errorAlert(message)
  }
}

const fetchFollowList = async (type, id) => {
  return await axiosPost(selectTypeEndpoint(type), {
    token: store.state.user.token,
    user_id: id
  })
}

export { follow, fetchFollowList }
