<template>
  <v-list-item>
    <Avatar :image="avatar" :link="`/profile/timeline/${userId}`" />
    <v-list-item-content class="text-start">
      <a @click="goPage(`/profile/timeline/${userId}`)">
        <h5>{{ fullname }}</h5>
      </a>
      <p class="mb-2">@{{ username }}</p>
      <p>{{ comment }}</p>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { goPage } from "../utils/app"

import Avatar from "./Avatar"
export default {
  props: {
    avatar: {
      type: String
    },
    fullname: {
      type: String
    },
    username: {
      type: String
    },
    comment: {
      type: String
    },
    userId: {
      type: String
    }
  },
  components: {
      Avatar
  },
  methods: {
    goPage(path) {
      goPage(path)
    }
  }
}
</script>
