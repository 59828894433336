<template>
  <ModalBase v-model="isActive" :maxWidth="500" :title="title">
    <template v-slot:body>
      <v-list-item v-for="(user, index) in users" :key="index">
        <v-list-item-avatar color="grey">
          <img :src="`${user.img}`" alt />
        </v-list-item-avatar>
        <v-list-item-content>
          <a :href="`/profile/timeline/${user.user_id}`">
            <h5>{{ user.fullname }}</h5>
          </a>
        </v-list-item-content>
      </v-list-item>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from "./ModalBase"
export default {
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    users: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: () => "ผู้ติดตาม"
    }
  },
  components: {
    ModalBase
  },
  computed: {
    isActive: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    },
  }
}
</script>
