import { mapState } from "vuex"

import { MSG_ADD_SUCCESS, MSG_CONFIRM_CHANGE_DELETE, MSG_VALIDATE_FILE, MSG_VALIDATE_IMAGE } from "../../../constants/messages"
import { ICON_COURSE_BLACK, ICON_PUBLIC } from "../../../constants/media"
import { API_COURSE, API_COURSE_CREATE, API_COURSE_UPDATE } from "../../../constants/endpoints"
import { rules } from "../../../utils/validate"
import { courseForm } from "../../../constants/form"
import { createBase64 } from "../../../utils/app"
import { confirmAlert, errorAlert, successAlert } from "../../../utils/alert"
import { axiosPost } from "../../../utils/api"

import BtnUpload from "../../../components/buttons/BtnUpload.vue"
import CoverPreview from "../../../components/CoverPreview.vue"
import BtnSm from "../../../components/buttons/BtnSm.vue"
import SelectMd from "../../../components/SelectMd.vue"
import JointSplit from "../../../components/JointSplit.vue"
import VueGallerySlideshow from "vue-gallery-slideshow"
export default {
  data: () => ({
    iconCourse: ICON_COURSE_BLACK,
    iconPublic: ICON_PUBLIC,
    rules: {
      required: rules.required
    },
    id: 1,
    stepper: 1,
    course: courseForm,
    steps: [
      {
        completed: false
      },
      {
        completed: false
      },
      {
        completed: false
      }
    ],
    forms: [
      {
        valid: false,
        ref: "form1"
      },
      {
        valid: false,
        ref: "form2"
      },
      {
        valid: false,
        ref: "form3"
      }
    ],
    privacies: [
      { status: "public", text: "สาธารณะ" },
      { status: "private", text: "เฉพาะฉัน" }
    ],
    imgPreview: "",
    filename: [],
    link: [""],
    imgIndex: null
  }),
  components: {
    BtnUpload,
    CoverPreview,
    BtnSm,
    SelectMd,
    JointSplit,
    VueGallerySlideshow
  },
  mounted() {
    this.fetchCourse()
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    async fetchCourse() {
      const id = this.$route.params.id
      if (id) {
        const { data } = await axiosPost(API_COURSE, {
          token: this.user.token,
          post_id: id
        })

        const { caption, title, subject, post_id, course_id, description, privacy, chapter, img } = data
        this.course = {
          caption: caption,
          title: title,
          subject: subject,
          post_id: post_id,
          course_id: course_id,
          description: description,
          privacy: privacy,
          group: data.group || null,
          file_cover: [],
          file_cover_type: [],
          chapter: chapter
        }
        if (img) {
          this.imgPreview = img
        }
      }

      if (this.$route.query.groupID) {
        this.course.privacy = "group"
        this.course.group = this.$route.query.groupID
      }
    },
    async nextTab(page) {
      if (page == 3 && !this.course.chapter.length) {
        await errorAlert("เพิ่มอย่างน้อย 1 บทเรียน")
        return
      }
      const isvalid = this.$refs[`${this.forms[page - 2].ref}`].validate()
      if (isvalid) {
        this.stepper = page
      }
    },
    prevTab(page) {
      this.stepper = page
    },
    toTop() {
      window.scrollTo(0, 0)
    },
    pushChapter() {
      this.course.chapter.push({
        title: null,
        sub_chapter: []
      })
    },
    async deleteChapter(index) {
      const isConfirm = await confirmAlert(MSG_CONFIRM_CHANGE_DELETE)
      if (isConfirm) {
        this.course.chapter.splice(index, 1)
      }
    },
    pushSubChapter(index) {
      this.course.chapter[index].sub_chapter.push({
        title: null,
        video: [],
        files: []
      })
    },
    async deleteSubChapter(chapterIndex, subIndex) {
      const isConfirm = await confirmAlert(MSG_CONFIRM_CHANGE_DELETE)
      if (isConfirm) {
        this.course.chapter[chapterIndex].sub_chapter.splice(subIndex, 1)
      }
    },
    addLink(chapterIndex, subIndex) {
      this.course.chapter[chapterIndex].sub_chapter[subIndex].video.push({
        title: null,
        url: null,
        description: null
      })
    },
    async deleteLink(chapterIndex, subIndex, index) {
      const isConfirm = await confirmAlert(MSG_CONFIRM_CHANGE_DELETE)
      if (isConfirm) {
        this.course.chapter[chapterIndex].sub_chapter[subIndex].video.splice(index, 1)
      }
    },
    addFile(chapterIndex, subIndex) {
      this.course.chapter[chapterIndex].sub_chapter[subIndex].files.push({
        title: null,
        file: null,
        file_type: null,
        file_name: null
      })
    },
    async deleteFile(chapterIndex, subIndex, index) {
      const isConfirm = await confirmAlert(MSG_CONFIRM_CHANGE_DELETE)
      if (isConfirm) {
        this.course.chapter[chapterIndex].sub_chapter[subIndex].files.splice(index, 1)
      }
    },
    async onChangeFile(file, index, indexSub, indexFile) {
      const imgExt = ["application/pdf"]
      if (file.type && imgExt.includes(file.type) && file.size < 5000000) {
        const result = await createBase64(file)
        this.course.chapter[index].sub_chapter[indexSub].files[indexFile].file = result.split(",")[1]
        this.course.chapter[index].sub_chapter[indexSub].files[indexFile].file_type = file.type.split("/")[1]
        this.course.chapter[index].sub_chapter[indexSub].files[indexFile].file_name = this.course.chapter[index].sub_chapter[indexSub].files[indexFile].file_name || file.name
      } else {
        await errorAlert(MSG_VALIDATE_FILE)
      }
    },
    async onchangeHandler(image) {
      const imgExt = ["image/png", "image/jpg", "image/jpeg"]
      if (image.type && imgExt.includes(image.type) && image.size < 5000000) {
        const result = await createBase64(image)
        this.course.file_cover.push(result)
        this.course.file_cover_type.push(image.type.split("/")[1])
        this.imgPreview = URL.createObjectURL(image)
      } else {
        await errorAlert(MSG_VALIDATE_IMAGE)
      }
    },
    async removeImage() {
      if (this.course.file_cover.length && this.course.file_cover_type.length) {
        this.course.file_cover.pop()
        this.course.file_cover_type.pop()
      }
      this.imgPreview = ""
    },

    async submit() {
      let endpoint = API_COURSE_CREATE
      if (this.$route.name == "EditCourse") {
        endpoint = API_COURSE_UPDATE
      }
      const { code, message } = await axiosPost(endpoint, { token: this.user.token, ...this.course })
      if (code == 200) {
        await successAlert(MSG_ADD_SUCCESS)
        location.reload()
      } else {
        await errorAlert(message)
      }
    }
  }
}
