import store from "../store"

import { MSG_CONFIRM_CHANGE_DELETE } from "../constants/messages"
import {
  API_POST_CREATE, API_POST_EMOTICON, API_POST_FAVORITE, API_POST_UPDATE, API_COURSE_DELETE, API_POST_DELETE
} from "../constants/endpoints"
import { axiosPost, formDataPost } from "../utils/api"
import { confirmAlert } from "../utils/alert"
const createPost = async (formData) => {
  return await formDataPost(API_POST_CREATE, formData)
}
// const createPost = async (formData) => {
//   return await axiosPost(API_POST_CREATE, formData)
// }

const updatePost = async (formData) => {
  return await formDataPost(API_POST_UPDATE, formData)
}

const createCourse = (body) => { }

const updateCourse = (body, id) => { }

const like = async (id) => {
  return await axiosPost(API_POST_EMOTICON, {
    token: store.state.user.token,
    post_id: id,
    user_id: store.state.user.userId,
    action: "like"
  })
}

const favorite = async (id) => {
  return await axiosPost(API_POST_FAVORITE, {
    token: store.state.user.token,
    post_id: id,
    user_id: store.state.user.userId,
    action: "favorite"
  })
}

const deletePost = async (id, type) => {
  const isConfirm = await confirmAlert(MSG_CONFIRM_CHANGE_DELETE)
  if (isConfirm) {
    let endpoint = API_COURSE_DELETE
    if (type == 'post') {
      endpoint = API_POST_DELETE
    }

    return await axiosPost(endpoint, {
      token: store.state.user.token,
      post_id: id
    })
  }

}

export { createPost, updatePost, createCourse, updateCourse, like, favorite, deletePost }
