<template>
  <v-col :class="layout" class="text-start py-2">
    <label class="ml-2" v-if="label">{{ label }}<span class="required" v-if="isRequired">*</span></label>
    <v-text-field
      v-model="input"
      :class="`input-${inputStyle}`"
      :type="type !== 'password' ? type : passwordVisible ? 'text' : 'password'"
      :label="label"
      :placeholder="placeholder"
      :rules="validates"
      :prepend-inner-icon="icon"
      :append-icon="type !== 'password' ? '' : passwordVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline mdi-flip-h'"
      @click:append="passwordVisible = !passwordVisible"
      :disabled="isDisabled"
      hide-details="auto"
      dense
      solo
      outlined
      autocomplete
      :color="color"
    ></v-text-field>
  </v-col>
</template>

<script>
export default {
  data: () => ({
    passwordVisible: false
  }),
  props: {
    value: {
      type: [String, Number]
    },
    label: {
      type: String,
      default: () => ""
    },
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      default: () => "text"
    },
    validates: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String
    },
    layout: {
      type: String,
      default: () => "col-12 col-sm-6 col-md-6 col-lg-5"
    },
    inputStyle: {
      type: String,
      default: () => "pill"
    },
    isRequired: {
      type: Boolean,
      default: () => false
    },
    isDisabled: {
      type: Boolean,
      default: () => false
    },
    color: {
      type: String,
      default: () => "secondary"
    }
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  }
}
</script>
