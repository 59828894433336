import Vue from "vue"

const filterSize = (number) => {
  const result = number / 1000000
  const fixedDemical = result.toFixed(3)
  return fixedDemical.toString() + "mb"
}

const filterDateToString = (date) => {
  const newDate = new Date(date)
  return (
    newDate.getFullYear() +
    "-" +
    (newDate.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    newDate
      .getDate()
      .toString()
      .padStart(2, "0")
  )
}

const filterBaseName = (filePath) => {
  let filename = new String(filePath).substring(filePath.lastIndexOf('/') + 1)
  if (filename.lastIndexOf(".") != -1)
    filename = filename.substring(0, filename.lastIndexOf("."))
  return filename + ".pdf"
}

export { filterSize, filterDateToString, filterBaseName }

Vue.filter("filterSize", filterSize)
Vue.filter("filterBaseName", filterBaseName)
