import { mapState } from "vuex"

import { API_PROFILE } from "../../constants/endpoints"
import { MSG_FOLLOW_SUCCESS, MSG_UNFOLLOW_SUCCESS, MSG_CONFIRM_UNFOLLOW } from "../../constants/messages"
import { confirmAlert, errorAlert, successAlert } from "../../utils/alert"

import { goPage } from "../../utils/app"
import { axiosPost } from "../../utils/api"
import { fetchFollowList, follow } from "../../services/follow"

import Avatar from "../../components/Avatar.vue"
import BtnSm from "../../components/buttons/BtnSm.vue"
import BtnMd from "../../components/buttons/BtnMd.vue"
import BtnLg from "../../components/buttons/BtnLg.vue"
import BtnFollow from "../../components/buttons/BtnFollow.vue"
import ToolbarProfile from "../../components/toolbars/ToolbarProfile.vue"
import ModalFollow from "../../components/modal/ModalFollow.vue"

export default {
  data: () => ({
    userProfile: {},
    followList: [],
    isModal: false,
    titleCard: null,
    isStarter: false,
    isActive: false,
    title: "ผู้ติดตาม"
  }),
  components: {
    Avatar,
    BtnSm,
    BtnMd,
    BtnLg,
    BtnFollow,
    ToolbarProfile,
    ModalFollow
  },
  mounted() {
    this.fetchUser()
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    async fetchUser() {
      const id = this.$route.params.id
      if (id) {
        const { data, code } = await axiosPost(API_PROFILE, {
          token: this.user.token,
          user_id: id
        })
        if (code == 200) {
          this.userProfile = data
          this.isStarter = true
        }
      }
    },
    async onFollow(currentStatus) {
      if (currentStatus) {
        const isConfirm = confirmAlert(MSG_CONFIRM_UNFOLLOW)
        if (!isConfirm) return
      }
      await follow(currentStatus, this.userProfile.user_id)
      this.fetchUser()
    },
    async fetchFollowList(type) {
      const id = this.$route.params.id
      const { data, code } = await fetchFollowList(type, id)
      if (code == 200) {
        this.followList = data
      }
    },
    goPage(path) {
      goPage(path)
    },
    async openDialog(type) {
      if (type == "follower") {
        this.title = "ผู้ติดตาม"
      } else {
        this.title = "กำลังติดตาม"
      }
      const {data: users} = await fetchFollowList(type, this.userProfile.user_id)

      if (users.length) {
        this.followList = users
        this.isActive = true
      }
    }
  },
  watch: {
    $route() {
      this.fetchUser()
    }
  }
}
