<template>
  <ModalBase v-model="isActive" :maxWidth="500" title="ลืมรหัสผ่าน">
    <template v-slot:body>
      <v-form v-model="isValid" class="mt-8" ref="emailForm" lazy-validation>
        <v-row class="justify-center">
          <InputGroup
            v-model="email"
            layout="col-12"
            label="โปรดระบุอีเมล"
            placeholder="example@mail.com"
            :rules="[rules.required]"
          />
          <v-flex xs12></v-flex>
          <BtnSm class="btn-primary btn-rounded-pill mt-8" @click.native="submitHandler()">
            <v-icon class="mr-2">mdi-email</v-icon> ส่งอีเมล
          </BtnSm>
        </v-row>
      </v-form>
    </template>
  </ModalBase>
</template>

<script>
/**
 * IMPORT COMPONENTS
 */
import ModalBase from "./ModalBase"
import InputGroup from "../InputGroup"
import BtnSm from "../buttons/BtnSm"

/**
 * IMPORT CONSTANTS
 */
import { rules } from "../../utils/validate"
export default {
  data: () => ({
    isValid: false,
    email: null,
    rules: {
      required: rules.required
    }
  }),
  components: {
    ModalBase,
    InputGroup,
    BtnSm
  },
  props: {
    value: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    isActive: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },
  methods: {
    submitHandler() {
      const isValid = this.$refs.emailForm.validate()
      if (isValid) {
        this.$emit("submit", this.email)
      }
    }
  }
}
</script>
