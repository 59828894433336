<template>
  <ModalBase v-model="isActive" :maxWidth="700" :title="title">
    <template v-slot:body>
      <Avatar class="my-8" :image="img" :size="100" />
      <h4 class="mb-8">{{ fullname }}</h4>
      <BtnSm class="btn-primary btn-rounded-pill mr-2" @click.native="changeStatus(id,type)">{{
        type == "member" ? "ปรับเป็นผู้ดูแล" : "ปรับเป็นสมาชิก"
      }}</BtnSm>
      <BtnSm class="btn-light btn-rounded-pill" @click.native="remove(id, type)">ลบออกจากกลุ่ม</BtnSm>
    </template>
  </ModalBase>
</template>

<script>
import { mapState } from "vuex"
import { API_GROUP_REMOVE_MEMBER, API_GROUP_SET_COLEADER, API_GROUP_UNSET_COLEADER } from "../../constants/endpoints"
import { MSG_CHANGE_SUCCESS, MSG_CONFIRM_CHANGE} from "../../constants/messages"
import { confirmAlert, errorAlert, successAlert } from "../../utils/alert"
import { axiosPost } from "../../utils/api"

import Avatar from "../Avatar"
import ModalBase from "./ModalBase"
import InputGroup from "../InputGroup"
import TextArea from "../TextArea"
import BtnSm from "../buttons/BtnSm"
export default {
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    title: {
      type: String
    },
    id: {
      type: String
    },
    img: {
      type: String
    },
    fullname: {
      type: String
    },
    type: {
      type: String
    }
  },
  components: {
    Avatar,
    ModalBase,
    InputGroup,
    TextArea,
    BtnSm
  },
  computed: {
    ...mapState(["user"]),
    isActive: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },
  methods: {
    async changeStatus(id, type) {
      let endpoint = API_GROUP_UNSET_COLEADER
      if (type == "member") {
        endpoint = API_GROUP_SET_COLEADER
      }

      const isConfirm = await confirmAlert(MSG_CONFIRM_CHANGE + "สถานะ?")
      if (isConfirm) {
        const { code, message } = await axiosPost(endpoint, {
          token: this.user.token,
          group_id: this.$route.params.id,
          user_id: id,
          member_type: type
        })
        if (code == 200) {
          await successAlert(MSG_CHANGE_SUCCESS)
          this.isActive = false
        } else {
          await errorAlert(message)
        }
      }
    },
    async remove(id, type) {
      const isConfirm = await confirmAlert(MSG_CONFIRM_CHANGE + "สถานะ?")
      if (isConfirm) {
        const { code, message } = await axiosPost(API_GROUP_REMOVE_MEMBER, {
          token: this.user.token,
          group_id: this.$route.params.id,
          user_id: id,
          member_type: type
        })
        if (code == 200) {
          await successAlert(MSG_CHANGE_SUCCESS)
          this.isActive = false
        } else {
          await errorAlert(message)
        }
      }
    }
  }
}
</script>
