import { mapState } from "vuex"

import { IMG_LOGO } from "../../../constants/media"
import { API_FEED_TIMELINE } from "../../../constants/endpoints"
import { axiosPost } from "../../../utils/api"

import CardPost from "../../../components/CardPost.vue"
export default {
  data: () => ({
    imgLogo: IMG_LOGO,
    countComment: 1,
    countLike: 2,
    countShare: 3,
    countFavorite: 4,
    feed: [],
    isStarter: false
  }),
  mounted() {
    this.fetchFeed()
  },
  components: {
    CardPost
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    async fetchFeed() {
      const { data: feed } = await axiosPost(API_FEED_TIMELINE, {
        token: this.user.token,
        user_id: this.$route.params.id
      })

      if (feed.length) {
        this.feed = feed
        this.isStarter = true
      }
    },
    editFeed(id, type) {
      if (type == "post") {
        this.$router.push({
          path: `/post/edit/${id}`
        })
      } else {
        this.$router.push({
          path: `/course/edit/${id}`
        })
      }
    },
    processSuccess() {
      this.fetchFeed()
    }
  }
}
