<template>
  <div class="wrapper-gallery">
    <figure
      class="gallery-item"
      :class="
        gallery.length == 1
          ? 'gallery-item-cover'
          : gallery.length == 2 && index == 1
          ? 'gallery-item-half-left'
          : gallery.length == 2 && index == 2
          ? 'gallery-item-half-left'
          : gallery.length == 3 && index == 1
          ? 'gallery-item-large-left'
          : gallery.length == 3 && index == 2
          ? 'gallery-item-small-top'
          : 'gallery-item-small-bottom'
      "
      v-for="(image, index) in gallery"
      :key="image.file._id"
      @click="openImg(index)"
    >
      <img :src="`${apiURL}/${image.file.file}`" class="gallery-img" alt="Image 1" />
    </figure>
    <VueGallerySlideshow :images="images" :index="imgIndex" @close="close" v-if="images.length" />
  </div>
</template>

<script>
import { API_BASE_URL } from "../../constants/config"
import VueGallerySlideshow from "vue-gallery-slideshow"
export default {
  data: () => ({
    apiURL: API_BASE_URL,
    imgIndex: null
  }),
  props: {
    gallery: {
      type: Array
    },
    images: {
      type: Array
    }
  },
  components: {
    VueGallerySlideshow
  },
  methods: {
    openImg(index) {
      this.imgIndex = index
    },
    close() {
      this.imgIndex = null
    }
  }
}
</script>
