<template>
  <v-btn class="btn-sm" :loading="isLoading" :disabled="isDisabled" :to="link">
    <slot>Click</slot>
  </v-btn>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean
    },
    isDisabled: {
      type: Boolean
    },
    link: {
      type: String
    }
  }
}
</script>
