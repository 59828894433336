<template>
  <div class="edit">
    <ToolbarProfile />
    <v-main>
      <div class="wrapper wrapper-edit">
        <div class="side-edit">
          <ul class="w-100 pl-0">
            <li class="side-list-header">
              <h4 class="text-header">{{ this.user.fullname }}</h4>
              <h6 class="text-subheader">@{{ this.user.username }}</h6>
            </li>
            <router-link :to="`/editprofile/avatar/${this.user.userId}`" @click.native="switchRoute()">
              <li class="side-list-link justify-space-between pa-4">
                <h6 :class="{ 'text-primary': $route.name == 'avatar' }">แก้ไขรูปภาพโปรไฟล์</h6>
                <v-icon class="icon-right" :class="{ 'text-icon-primary': $route.name == 'avatar' }"
                  >mdi-chevron-right</v-icon
                >
              </li>
            </router-link>
            <router-link :to="`/editprofile/cover/${this.user.userId}`" @click.native="switchRoute()">
              <li class="side-list-link justify-space-between pa-4">
                <h6 :class="{ 'text-primary': $route.name == 'cover' }">แก้ไขรูปภาพหน้าปก</h6>
                <v-icon class="icon-right" :class="{ 'text-icon-primary': $route.name == 'cover' }"
                  >mdi-chevron-right</v-icon
                >
              </li>
            </router-link>
            <router-link :to="`/editprofile/information/${this.user.userId}`" @click.native="switchRoute()">
              <li class="side-list-link justify-space-between pa-4">
                <h6 :class="{ 'text-primary': $route.name == 'information' }">แก้ไขประวัติส่วนตัว</h6>
                <v-icon class="icon-right" :class="{ 'text-icon-primary': $route.name == 'information' }"
                  >mdi-chevron-right</v-icon
                >
              </li>
            </router-link>
            <router-link :to="`/editprofile/changepassword/${this.user.userId}`" @click.native="switchRoute()">
              <li class="side-list-link justify-space-between pa-4">
                <h6 :class="{ 'text-primary': $route.name == 'changePassword' }">แก้ไขรหัสผ่าน</h6>
                <v-icon class="icon-right" :class="{ 'text-icon-primary': $route.name == 'changePassword' }"
                  >mdi-chevron-right</v-icon
                >
              </li>
            </router-link>
          </ul>
        </div>
        <v-main class="wrapper-edit-page">
          <div class="top-header">
            <h5 class="text-primary middle">แก้ไขข้อมูลส่วนตัว</h5>
            <v-menu offset-y class="d-flex d-md-none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="d-flex d-md-none">
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in menuList" :key="`list-${index}`" :to="item.link">
                  <v-list-item-title :class="{ 'text-primary': $route.name == item.name }">{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <v-container>
            <v-card>
              <v-card-title class="d-flex justify-center">
                {{ title }}
              </v-card-title>
              <v-divider class="mb-4"></v-divider>
              <v-card-text class="px-2">
                <router-view />
              </v-card-text>
            </v-card>
          </v-container>
        </v-main>
      </div>
    </v-main>
  </div>
</template>

<script>
import { mapState } from "vuex"

import ToolbarProfile from "../components/toolbars/ToolbarProfile"
export default {
  data: () => ({
    title: "แก้ไขรูปภาพโปรไฟล์",
  }),
  components: {
    ToolbarProfile
  },
  mounted() {
    this.switchRoute()
  },
  computed: {
    ...mapState(["user"]),
    menuList() {
      let id = this.$route.params.id
      return [
        { name: "avatar", title: "แก้ไขรูปภาพโปรไฟล์", link: `/editprofile/avatar/${id}` },
        { name: "cover", title: "แก้ไขรูปภาพหน้าปก", link: `/editprofile/cover/${id}` },
        { name: "information", title: "แก้ไขประวัติส่วนตัว", link: `/editprofile/information/${id}` },
        { name: "changePassword", title: "แก้ไขรหัสผ่าน", link: `/editprofile/changepassword/${id}` }
      ]
    }
  },
  methods: {
    switchRoute() {
      const routeName = this.$route.name
      switch (routeName) {
        case "avatar":
          this.title = "แก้ไขรูปภาพโปรไฟล์"
          break

        case "cover":
          this.title = "แก้ไขรูปภาพหน้าปก"
          break

        case "information":
          this.title = "แก้ไขประวัติส่วนตัว"
          break

        case "changePassword":
          this.title = "แก้ไขรหัสผ่าน"
          break

        default:
          break
      }
    }
  }
}
</script>
