<template>
  <v-app-bar absolute class="transparent" elevation="0">
    <v-spacer></v-spacer>
    <p class="mr-2 d-none d-sm-inline">ต้องการ{{ text }}?</p>
    <v-btn class="btn-tag-primary" :to="path">
      {{ text }}
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    text: {
      type: String
    },
    path: {
      type: String
    }
  }
}
</script>
