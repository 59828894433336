<template>
  <div class="input-wrapper" :style="{ 'max-width': maxWidth, 'max-height': maxHeight }">
    <input class="input-transparent" type="file" @change="onchangeHandler" @drag="onchangeHandler" ref="inputFile" :accept="extAccept" />
    <slot>
      <v-card class="preview-img text-center" @click="openFileDialog()">
        <v-icon size="50">mdi-plus</v-icon>
        <div class="white--text text-bottom">เลือกได้เฉพาะ .png, .jpeg, .jpg</div>
      </v-card>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    extAccept: {
      type: String,
      default: () => ".jpg,.jpeg,.png,.pdf"
    },
    maxWidth: {
      type: String,
      default: () => "100%" 
    },
    maxHeight: {
      type: String,
      default: () => "100%" 
    }
  },
  methods: {
    openFileDialog() {
      this.$refs.inputFile.click()
    },
    onchangeHandler(e) {
      const { files } = e.target
      this.$emit("onchangeHandler", files[0])
      this.$refs.inputFile.value = null
    }
  }
}
</script>
