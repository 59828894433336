import { mapState } from "vuex"

import { API_NOTIFICATION } from "../../constants/endpoints"
import { axiosPost } from "../../utils/api"

export default {
    data: () => ({
        feed: [],
        isStarter: false
    }),
    mounted() {
        this.fetchFeed()
    },
    computed: {
        ...mapState(["user"]),
        unreads() {
            return this.feed.filter(post => post.is_read == 0)
        },
        reads() {
            return this.feed.filter(post => post.is_read == 1)
        }
    },
    methods: {
        async fetchFeed() {
            const { data: posts, code } = await axiosPost(API_NOTIFICATION, {
                token: this.user.token,
                user_id: this.user.userId
            })

            if (code == 200) {
                this.feed = posts
            }

            this.isStarter = true
        }
    }
}