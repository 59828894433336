const IMG_LOGO = require("@/assets/logo/Sut-logo.png")
const IMG_FACEBOOK = require("@/assets/icon/social/Facebook.png")
const IMG_GOOGLE = require("@/assets/icon/social/Google+.png")
const ICON_UPLOAD = require("@/assets/icon/post/upload.svg")
const ICON_POST = require("@/assets/icon/post/img.svg")
const ICON_CLIP = require("@/assets/icon/post/clip.svg")
const ICON_COURSE = require("@/assets/icon/post/Icon_Course.svg")
const ICON_COURSE_BLACK = require("@/assets/icon/post/Icon_Course_black.svg")
const ICON_COURSE_GREY = require("@/assets/icon/post/Icon_Course_grey.svg")
const ICON_BOOK = require("@/assets/icon/post/book.svg")
const ICON_BOOK_BLACK = require("@/assets/icon/post/book-dark.svg")
const ICON_PUBLIC = require("@/assets/icon/post/public.svg")
const ICON_HAPPY = require("@/assets/icon/post/happy.svg")

export {
  IMG_LOGO,
  IMG_FACEBOOK,
  IMG_GOOGLE,
  ICON_UPLOAD,
  ICON_POST,
  ICON_CLIP,
  ICON_COURSE,
  ICON_COURSE_BLACK,
  ICON_COURSE_GREY,
  ICON_BOOK,
  ICON_BOOK_BLACK,
  ICON_PUBLIC,
  ICON_HAPPY
}
