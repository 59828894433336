<template>
  <v-autocomplete
    v-model="model"
    class="input-pill input-search"
    :items="searchData"
    :loading="isLoading"
    :search-input.sync="search"
    :filter="item => `${item.fullname} ${item.title}`" 
    label="ค้นหา... ผู้ใช้, คอร์ส"
    no-data-text="ไม่พบข้อมูล.."
    placeholder="ค้นหา... ผู้ใช้, คอร์ส"
    hide-details
    color="primary"
    solo
    outlined
    hide-selected
    return-object
    dense
  >
    <template v-slot:append>
      <v-icon>mdi-magnify</v-icon>
    </template>
    <template v-slot:selection="data">
      <p class="mb-0">{{data.item.fullname}}</p>
    </template>
    <template v-slot:item="data">
      <template>
        <v-list>
          <v-list-item v-if="(typeof data.item.post_id) == 'undefined'">
            <v-list-item-avatar color="grey">
              <img :src="`${data.item.img}`" alt />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <h6 class="mb-2 fw-600" v-if="(typeof data.item.fullname) !== 'undefined'">{{data.item.fullname}}</h6>
              </v-list-item-title>
              <v-list-item-subtitle>
                <h6 class="fw-600" v-if="(typeof data.item.username) !== 'undefined'">@{{data.item.username}}</h6>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else>
            <v-list-item-avatar color="grey">
              <img :src="`${data.item.img}`" alt />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <h6 class="mb-2 fw-600" v-if="(typeof data.item.title) !== 'undefined'">{{data.item.title}}</h6>
              </v-list-item-title>
              <v-list-item-subtitle>
                <h6 class=" fw-600" v-if="(typeof data.item.fullname) !== 'undefined'">{{data.item.fullname}}</h6>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState } from "vuex"
import {API_SEARCH} from "../../constants/endpoints"
import {axiosPost} from "../../utils/api"
export default {
  data:() => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    search: '',
    searchData: []
  }),
  computed: {
    ...mapState(["user"])
  },
  methods: {
    filterObject(item, queryText, itemText) {
    return (
      item.fullname.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
      -1 ||
      item.title.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    )
  },
  async queryData(val) {
    this.searchData = []
    this.isLoading = true
    const {data, code, message} = await axiosPost(API_SEARCH, {token: this.user.token, key_word: val})
    if (code == 200) {
      const {user, post} = data
      if (user.length) {
        this.searchData.push({ header: 'ผู้ใช้' })
        for (let i = 0; i < user.length; i++) {
          this.searchData.push(user[i])
        }
      }

      if (post.length) {
        this.searchData.push({ header: 'คอร์ส' })
        for (let j = 0; j < post.length; j++) {
          this.searchData.push(post[j])
        }
      }
    }
    this.isLoading = false
    }
  },
  watch: {
    model(val) {
      if (typeof val.title == 'undefined') {
        this.$router.push({
          path: `/profile/timeline/${val.user_id}`
        })
        location.reload()
      } else {
        this.$router.push({
          path: `/course/view/${val.post_id}`
        })
        location.reload()
      }
    },
    search(val) {
      if (!val) {
        this.isLoading = false
      } else {
        this.queryData(val)
      }
    }
  }
}
</script>