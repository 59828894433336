import { IMG_LOGO, IMG_FACEBOOK, IMG_GOOGLE } from "../../../constants/media"
import ToolbarAuth from "../../../components/toolbars/ToolbarAuth"
import BtnMd from "../../../components/buttons/BtnMd"
export default {
    data: () => ({
        imgLogo: IMG_LOGO,
        imgFacebook: IMG_FACEBOOK,
        imgGoogle: IMG_GOOGLE
    }),
    components: {
        ToolbarAuth,
        BtnMd
    },
    created() {
        if (this.$route.name == "student") {
            this.isActive = 0
        } else {
            this.isActive = 1
        }
    },
    watch: {
        $route(to, from) {
            this.activeRoute()
        },
    },
    methods: {
        activeRoute() {
            if (this.$route.name == "student") {
                this.isActive = 0
            } else {
                this.isActive = 1
            }
        },
    },
}