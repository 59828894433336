<template>
  <div>
    <v-app-bar class="py-0 px-0 px-sm-2" fixed top app color="rgb(255, 255, 255)" height="60px">
      <v-img class="pointer" :src="imgLogo" width="40px" @click.native="goPage('/')"></v-img>
      <v-toolbar-items class="align-self-center justify-space-between mx-2 mx-sm-10" style="width: 100%">
        <ToolbarSearch class="middle" />
      </v-toolbar-items>
      <!-- <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon class="mr-2" v-on="on" v-bind="attrs"></v-app-bar-nav-icon>
        </template>
        <v-list>
          <v-list-item to="/">
            <v-icon class="mr-2">mdi-home</v-icon>
            <p class="text-link">หน้าแรก</p>
          </v-list-item>
          <v-list-item to="/notification">
            <v-icon class="mr-2">mdi-bell</v-icon>
            <p class="text-link">แจ้งเตือน</p>
          </v-list-item>
          <v-list-item to="/saved">
            <v-icon class="mr-2">mdi-bookmark</v-icon>
            <p class="text-link">บันทึกไว้</p>
          </v-list-item>
          <v-list-item to="#">
            <v-icon class="mr-2">mdi-chat</v-icon>
            <p class="text-link">ข้อความ</p>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item to="/course">
            <v-icon class="mr-2">mdi-book-open-page-variant-outline</v-icon>
            <p class="text-link">คอร์สทั้งหมด</p>
          </v-list-item>
          <v-list-item to="/course">
            <v-icon class="mr-2">mdi-account-group</v-icon>
            <p class="text-link">กลุ่มทั้งหมด</p>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item to="#">
            <v-icon class="mr-2">mdi-cog</v-icon>
            <p class="text-link">ตั้งค่า</p>
          </v-list-item>
          <v-list-item to="#">
            <v-icon class="mr-2">mdi-help-circle</v-icon>
            <p class="text-link">ช่วยเหลือ</p>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <v-toolbar-items class="align-self-center justify-space-between" style="width: 20%">
        <Avatar
          class="pointer d-none d-md-flex"
          :image="user.img"
          @click.native="goPage(`/profile/timeline/${user.userId}`)"
        />
        <v-list-item-content class="pointer d-none d-md-flex nowrap" @click="logout()">
          <a>ออกจากระบบ</a>
        </v-list-item-content>
        <v-list-item-content class="pointer d-flex d-md-none">
          <v-menu offset-y class="d-flex d-md-none">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon small rounded v-on="on">
                <v-icon v-bind="attrs">mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <Avatar class="pointer" :image="user.img" @click.native="goPage(`/profile/timeline/${user.userId}`)" />
                <v-list-item-title @click="goPage(`/profile/timeline/${user.userId}`)">{{
                  user.fullname
                }}</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click.native="logout()">
                <v-list-item-title>ออกจากระบบ</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-content>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState } from "vuex"

import { IMG_LOGO, ICON_COURSE_GREY } from "../../constants/media"

import { goPage, logout } from "../../utils/app"

import Avatar from "../../components/Avatar"
import ToolbarSearch from "../../components/toolbars/ToolbarSearch"
import ModalCreatePost from "../../components/modal/ModalCreatePost"
export default {
  data: () => ({
    imgLogo: IMG_LOGO,
    iconCourse: ICON_COURSE_GREY
  }),
  components: {
    Avatar,
    ToolbarSearch,
    ModalCreatePost
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    logout: logout,
    goPage(page) {
      goPage(page)
    }
  }
}
</script>
