<template>
  <div class="course-all" v-if="courses.length">
    <v-row>
      <v-col class="col-12 d-flex justify-center">
        <div class="d-flex middle underline">
          <v-img class="mr-2" :src="iconCourseBlack" contain width="40" height="40"></v-img>
          <h5 class="end">คอร์สเรียนทั้งหมด</h5>
        </div>
      </v-col>
      <v-col class="col-4 col-md-4 text-start" v-for="(course, index) in courses" :key="`course-${index}`">
        <ImgPreview
          class="mb-4"
          :img="`${apiURL}/${course.files[0].file.file}`"
          disabledClose
          large
          @click.native="goPage(`/course/view/${course.post_id}`)"
        />
        <h5 class="text-center">{{ course.title }}</h5>
        <p class="wrapper-text-course-description mb-4">{{ course.description || "-" }}</p>
        <p class="error--text">
          <span><v-icon class="error--text">mdi-checkbox-blank-circle</v-icon></span> {{ course.subject }}
        </p>
      </v-col>
    </v-row>
  </div>
  <div class="mt-10 text-center" v-else>
    <h1 class="grey--text">ไม่มี Course</h1>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { API_BASE_URL } from "../../constants/config"
import { ICON_COURSE_BLACK } from "../../constants/media"
import { API_COURSE_LIST } from "../../constants/endpoints"
import { goPage } from "../../utils/app"
import { axiosPost } from "../../utils/api"

import BtnSm from "../../components/buttons/BtnSm"
import ImgPreview from "../../components/ImgPreview"
export default {
  data: () => ({
    apiURL: API_BASE_URL,
    iconCourseBlack: ICON_COURSE_BLACK,
    id: null,
    courses: []
  }),
  components: {
    BtnSm,
    ImgPreview
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
    }
    this.fetchCourse()
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    async fetchCourse() {
      const { data: courses } = await axiosPost(API_COURSE_LIST, {
        token: this.user.token,
        user_id: this.user.userId
      })
      if (courses.length) {
        this.courses = courses
      }
    },
    goPage(path) {
      goPage(path)
    }
  }
}
</script>
