import { mapMutations, mapState } from "vuex"

import { MSG_CONFIRM_CHANGE, MSG_UPDATE_SUCCESS, MSG_VALIDATE_IMAGE } from "../../../../constants/messages"
import { API_STUDENT_UPDATE, API_TEACHER_UPDATE } from "../../../../constants/endpoints"

import { confirmAlert, errorAlert, successAlert } from "../../../../utils/alert"
import { createBase64 } from "../../../../utils/app"
import { axiosPost } from "../../../../utils/api"

import CoverPreview from "../../../../components/CoverPreview.vue"
import BoxUpload from "../../../../components/BoxUpload.vue"
import BtnSm from "../../../../components/buttons/BtnSm"

let formData = new FormData()

export default {
  data: () => ({
    imgCover: null,
    isStarter: false,
    isLoading: false
  }),
  components: {
    CoverPreview,
    BoxUpload,
    BtnSm
  },
  mounted() {
    this.imgCover = this.user.cover
    this.isStarter = true
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    ...mapMutations({ setAuth: "SET_AUTH" }),
    async onchangeHandlerFile(image) {
      const imgExt = ["image/png", "image/jpg", "image/jpeg"]
      if (image.type && imgExt.includes(image.type) && image.size < 5000000) {
        formData = new FormData()
        formData.append("cover", image)
        this.imgCover = URL.createObjectURL(image)
      } else {
        await errorAlert(MSG_VALIDATE_IMAGE)
      }
    },
    async submit() {
      if (this.imgCover.includes("/upload")) return

      const isConfirm = await confirmAlert(MSG_CONFIRM_CHANGE + "รูปหน้าปกหรือไม่?")
      if (isConfirm) {
        this.isLoading = true
        let endpoint = API_TEACHER_UPDATE
        if (this.user.type == "student") {
          endpoint = API_STUDENT_UPDATE
        }
        
        formData.append("token", this.user.token)
        formData.append("user_id", this.user.userId)
        formData.append("file_type", "cover")

        const { data, code, message } = await axiosPost(endpoint, formData)
        if (code == 200) {
          this.setAuth(data)
          await successAlert(MSG_UPDATE_SUCCESS)
          location.reload()
        } else {
          formData.delete("token")
          formData.delete("user_id")
          formData.delete("file_type")
          await errorAlert(message)
        }
        this.isLoading = false
      }
    }
  }
}
