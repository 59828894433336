import { mapState } from "vuex"

import { IMG_LOGO } from "../../../constants/media"
import { axiosPost } from "../../../utils/api"
import { API_GROUP_FIND_TIMELINE } from "../../../constants/endpoints"

import CardPost from "../../../components/CardPost.vue"
export default {
  data: () => ({
    imgLogo: IMG_LOGO,
    postId: "123456489",
    caption: "caption",
    images: [],
    tag: "tag",
    countComment: 1,
    countLike: 2,
    countShare: 3,
    countFavorite: 4,

    feed: [],
    isStarter: false
  }),
  mounted() {
    this.fetchFeed()
  },
  components: {
    CardPost
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    async fetchFeed() {
      const { data: feed } = await axiosPost(API_GROUP_FIND_TIMELINE, {
        token: this.user.token,
        group_id: this.$route.params.id
      })
      if (feed.length) {
        this.feed = feed
        this.isStarter = true
      }
    }
  }
}
