import Vue from "vue"
import VueRouter from "vue-router"
import Router from "../components/Router.vue"
import store from "../store"
import NotFoundPage from "../views/NotFoundPage.vue"
import Main from "../views/Main.vue"
import MainPost from "../views/post/MainPost.vue"
import MainCourse from "../views/course/MainCourse.vue"
import MainGroup from "../views/group/MainGroup.vue"
import {
  Feed,
  Profile,
  Notification,
  Saved,
  Message,
  Setting,
  Help,
  Login,
  Register,
  StudentRegister,
  TeacherRegister,
  ViewPost,
  EditPost,
  ViewCourse,
  ViewCourseByGroup,
  CreateCourse,
  Timeline,
  Liked,
  // UserPost,
  UserSaved,
  EditProfile,
  EditAvatar,
  EditCover,
  EditInfo,
  EditPassword,
  AllCourse,
  AllGroup,
  MemberGroup,
  TimelineGroup,
  InviteGroup,
  ResetPassword
} from "./pages"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: Main,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "",
        name: "Feed",
        component: Feed
      },
      {
        path: "notification",
        name: "notification",
        component: Notification
      },
      {
        path: "saved",
        name: "saved",
        component: Saved
      },
      {
        path: "message",
        name: "message",
        component: Message
      },
      {
        path: "setting",
        name: "setting",
        component: Setting
      },
      {
        path: "help",
        name: "help",
        component: Help
      }
    ]
  },
  {
    path: "/post",
    component: MainPost,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "",
        redirect: {
          path: "/"
        }
      },
      {
        path: ":id",
        name: "ViewPost",
        component: ViewPost
      },
      {
        path: "edit/:id",
        name: "EditPost",
        component: EditPost
      }
    ]
  },
  {
    path: "/course",
    component: MainCourse,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "",
        redirect: "/course/list"
      },
      {
        path: "list",
        name: "AllCourse",
        component: AllCourse
      },
      {
        path: "view/:id",
        name: "ViewCourse",
        component: ViewCourse
      },
      {
        path: "view/:id/group/:groupId",
        name: "ViewCourseByGroup",
        component: ViewCourseByGroup
      },
      {
        path: "create",
        name: "CreateCourse",
        component: CreateCourse
      },
      {
        path: "edit/:id",
        name: "EditCourse",
        component: CreateCourse
      }
    ]
  },
  {
    path: "/profile",
    component: Profile,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "",
        redirect: {
          path: "/profile/timeline"
        }
      },
      {
        path: "timeline/:id",
        name: "timeline",
        component: Timeline
      },
      // {
      //   path: "post/:id",
      //   name: "UserPost",
      //   component: UserPost
      // },
      {
        path: "liked/:id",
        name: "UserLiked",
        component: Liked
      },
      {
        path: "saved/:id",
        name: "UserSaved",
        component: UserSaved
      }
    ]
  },
  {
    path: "/editprofile",
    component: EditProfile,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "",
        redirect: {
          path: "/"
        }
      },
      {
        path: "avatar/:id",
        name: "avatar",
        component: EditAvatar
      },
      {
        path: "cover/:id",
        name: "cover",
        component: EditCover
      },
      {
        path: "information/:id",
        name: "information",
        component: EditInfo
      },
      {
        path: "changepassword/:id",
        name: "changePassword",
        component: EditPassword
      }
    ]
  },
  {
    path: "/group",
    component: Router,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "",
        name: "AllGroup",
        component: AllGroup
      },
      {
        path: ":id",
        component: MainGroup,
        children: [
          {
            path: "",
            name: "TimelineGroup",
            component: TimelineGroup,
          },
          {
            path: "member",
            name: "MemberGroup",
            component: MemberGroup,
          },
          {
            path: "invite",
            name: "InviteGroup",
            component: InviteGroup,
          }
        ]
      },
    ]
  },
  {
    path: "*",
    redirect: "/notfoundpage"
  },
  {
    path: "/notfoundpage",
    name: "NotFoundPage",
    component: NotFoundPage
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/register",
    component: Register,
    children: [
      {
        path: "",
        redirect: "/register/student"
      },
      {
        path: "student",
        name: "student",
        component: StudentRegister
      },
      {
        path: "teacher",
        name: "teacher",
        component: TeacherRegister
      }
    ]
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    component: ResetPassword
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (!store.getters.doneAuth) {
      next("/login")
      return
    } else {
      next()
    }
  } else {
    if (store.getters.doneAuth) {
      next("/")
      return
    } else {
      next()
    }
  }
})

export default router
