<template>
  <v-checkbox v-model="isChecked" :label="label" hide-details="auto"/>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean
    },
    label: {
      tpye: String,
      default: () => ""
    }
  },
  computed: {
    isChecked: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  }
}
</script>
