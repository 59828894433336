<template>
  <v-col :class="layout" class="text-start py-2">
    <label class="ml-2">{{ label }}<span class="required" v-if="isRequired">*</span></label>
    <v-radio-group
      v-model="input"
      :row="radioStyle == 'row' ? true : false"
      :column="radioStyle == 'column' ? true : false"
      :rules="rules"
      hide-details="auto"
      primary
    >
      <v-radio
        :label="`${itemText ? item[itemText] : item}`"
        :value="itemValue ? item[itemValue] : item"
        v-for="(item, i) in items"
        :key="`item + ${i}`"
      ></v-radio>
    </v-radio-group>
  </v-col>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Boolean]
    },
    layout: {
      type: String,
      default: () => "col-12"
    },
    label: {
      type: String,
      default: () => ""
    },
    valueType: {
      type: String,
      defalut: () => "text"
    },
    items: {
      tpye: Array
    },
    itemText: {
      type: String
    },
    itemValue: {
      type: String
    },
    rules: {
      type: Array,
      default: () => []
    },
    isRequired: {
      type: Boolean,
      default: () => false
    },
    radioStyle: {
      type: String,
      default: () => "row"
    }
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        if (this.valueType == "number") {
          this.$emit("input", Number(val))
        } else {
          this.$emit("input", val)
        }
      }
    }
  }
}
</script>
