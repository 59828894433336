export default {
    data() {
        return {
            search: null,
            message: [{
                    msg: "hello",
                    from: "user",
                },
                {
                    msg: "hello hello",
                    from: "chatwith",
                },
                {
                    msg: "hello hello hello",
                    from: "user",
                },
                {
                    msg: "hello",
                    from: "chatwith",
                },
                {
                    msg: "hello Hello hello",
                    from: "user",
                },
                {
                    msg: "hello",
                    from: "chatwith",
                },
                {
                    msg: "hello",
                    from: "user",
                },
                {
                    msg: "hello",
                    from: "chatwith",
                },
            ],
        };
    },
};