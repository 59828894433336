/* VALIDATE */
const RULES_MESSAGE = {
  required: "โปรดระบุข้อมูล",
  fullname: "ข้อมูลไม่ถูกต้อง โปรดระบุในรูปแบบ ชื่อจริง<เว้นวรรค>นามสกุล",
  email: "อีเมลไม่ถูกต้อง",
  password: "รหัสผ่านไม่ถูกต้อง",
  passwordMatch: "รหัสผ่านไม่ตรงกัน",
  phone: "รูปแบบของหมายเลขโทรศัพท์ไม่ถูกต้อง",
  atleast: "ระบุอย่างน้อย",
  notMore: "ระบุไม่เกิน",
  number: "โปรดระบุเลข",
  invalid: "ข้อมูลไม่ถูกต้อง",
  idCard: "เลขบัตรประชาชนไม่ถูกต้อง โปรดระบุเลข 13 ตัว",
  branch: "ข้อมูลไม่ถูกต้อง โปรดระบุเลข 5 ตัว",
}

const rules = {
  required: (v) => !!v || RULES_MESSAGE.required,
  fullname: (v) => /^\S+\s+\S/.test(v) || RULES_MESSAGE.fullname,
  phone: (v) => (v && (v.length == 9 || v.length == 10) && /^[0-9]/.test(v)) || RULES_MESSAGE.phone,
  number: (v) => /^[0-9]/.test(v) || RULES_MESSAGE.number,
  email: (v) => /^\S+@\S+\.\S+/.test(v) || RULES_MESSAGE.email,
  password: (v) => (v && v.length >= 8 && /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v)) || RULES_MESSAGE.password,
  match: (v, w) => v === w || RULES_MESSAGE.passwordMatch,
  min: (v, length = 8) => (v && v.length > length) || RULES_MESSAGE.atleast + `${length} ตัว`,
  idCard: (v) => (v && v.length == 13) || RULES_MESSAGE.idCard
}

export { rules }
