/**
 * AUTHENICATION ENDPOINTS
 */
const API_STUDENT_CREATE = "student/create/"
const API_STUDENT_UPDATE = "student/update/"
const API_TEACHER_CREATE = "teacher/create/"
const API_TEACHER_UPDATE = "teacher/update/"
const API_LOGIN = "login/"
const API_LOGIN_SOCIAL = "login/social/"
const API_LOGOUT = "logout/"
const API_FORGET_PASSWORD = "forgotpassword/"
const API_VALIDATE_PASSWORD = "validatepassword/"
const API_RESET_PASSWORD = "resetpassword/"
const API_CHANGE_PASSWORD = "profile/changePassword"
const API_PROFILE = "user/profile/"

/**
 * POSTS & COURSES ENDPOINTS
 */
const API_POST = "post/"
const API_POST_DEFAULT = "post/default/"
const API_POST_CREATE = "post/create/"
const API_POST_UPDATE = "post/update/"
const API_POST_DELETE = "post/delete/"
const API_COMMENT_CREATE = "comment/create/"
const API_SUB_COMMENT_CREATE = "subComment/create/"
const API_POST_EMOTICON = "post/emoticon"
const API_POST_FAVORITE = "post/favorite"
const API_COURSE = "course/"
const API_COURSE_LIST = "course/list/"
const API_COURSE_CREATE = "course/create/"
const API_COURSE_UPDATE = "course/update/"
const API_COURSE_DELETE = "course/delete"

/**
 * FEED ENDPOINTS
 */
const API_FEED = "feed/"
const API_FEED_FAVORITE = "user/favorite/"
const API_FEED_LIKE = "user/like/"
const API_FEED_TIMELINE = "user/timeline/"

/**
 * FOLLOW ENDPOINTS
 */
const API_FOLLOW = "follow/"
const API_UNFOLLOW = "unfollow/"
const API_FOLLOWER_LIST = "follower/list"
const API_FOLLOWING_LIST = "following/list"

/**
 * APPLICATION ENDPOINTS
 */
const API_NOTIFICATION = "notification/"
const API_NOTIFICATION_COUNT = "notification/count/"
const API_SEARCH = "search/"
const API_NAV = "nav/"

/**
 * GROUP ENDPOINTS
 */
const API_GROUP = "group/"
const API_GROUP_FIND = "group/findGroup/"
const API_GROUP_FIND_MEMBER = "group/findMemberGroup/"
const API_GROUP_FIND_MEMBER_JOIN = "group/findMemberJoin/"
const API_GROUP_REMOVE_MEMBER = "group/removeMember/"
const API_GROUP_CREATE = "group/create/"
const API_GROUP_FIND_TIMELINE = "group/findTimeline/"
const API_GROUP_INVITE = "group/inviteGroup/"
const API_GROUP_JOIN = "group/joinGroup/"
const API_GROUP_ACCEPT = "group/acceptMember/"
const API_GROUP_REJECT = "group/closeRequest/"
const API_GROUP_SEARCH_USER = "group/search/user/"
const API_GROUP_SET_COLEADER = "group/setColeader"
const API_GROUP_UNSET_COLEADER = "group/unsetColeader"
const API_GROUP_UPDATE = "group/update"
const API_GROUP_LEAVE = "group/leaveGroup"

/**
 * TEST
 */
const API_TEST = "profile/uploadimage/"

export {
  API_STUDENT_CREATE,
  API_STUDENT_UPDATE,
  API_TEACHER_CREATE,
  API_TEACHER_UPDATE,
  API_LOGIN,
  API_LOGIN_SOCIAL,
  API_LOGOUT,
  API_FORGET_PASSWORD,
  API_VALIDATE_PASSWORD,
  API_RESET_PASSWORD,
  API_CHANGE_PASSWORD,
  API_PROFILE,
  API_POST,
  API_POST_DEFAULT,
  API_POST_CREATE,
  API_POST_UPDATE,
  API_POST_DELETE,
  API_COMMENT_CREATE,
  API_SUB_COMMENT_CREATE,
  API_POST_EMOTICON,
  API_POST_FAVORITE,
  API_COURSE,
  API_COURSE_LIST,
  API_COURSE_CREATE,
  API_COURSE_UPDATE,
  API_COURSE_DELETE,
  API_FEED,
  API_FEED_FAVORITE,
  API_FEED_LIKE,
  API_FEED_TIMELINE,
  API_FOLLOW,
  API_UNFOLLOW,
  API_FOLLOWER_LIST,
  API_FOLLOWING_LIST,
  API_NOTIFICATION,
  API_NOTIFICATION_COUNT,
  API_SEARCH,
  API_NAV,
  API_GROUP,
  API_GROUP_FIND,
  API_GROUP_FIND_MEMBER,
  API_GROUP_FIND_MEMBER_JOIN,
  API_GROUP_REMOVE_MEMBER,
  API_GROUP_CREATE,
  API_GROUP_FIND_TIMELINE,
  API_GROUP_INVITE,
  API_GROUP_JOIN,
  API_GROUP_ACCEPT,
  API_GROUP_REJECT,
  API_GROUP_SEARCH_USER,
  API_GROUP_SET_COLEADER,
  API_GROUP_UNSET_COLEADER,
  API_GROUP_UPDATE,
  API_GROUP_LEAVE,
  API_TEST
}
