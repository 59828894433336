import Vue from "vue"
import VueSimpleAlert from "vue-simple-alert"
Vue.use(VueSimpleAlert, { reverseButtons: true })

let simpleAlert = (msg) => {
  VueSimpleAlert.alert(msg)
}

let successAlert = async (msg) => {
  VueSimpleAlert.fire({
    title: `${msg}`,
    type: "success",
    timer: 3000
  }).then(() => {
    return true
  })
}

let confirmAlert = async (msg) => {
  return await VueSimpleAlert.confirm(msg)
    .then(() => {
      return true
    })
    .catch(() => {
      return false
    })
}

let errorAlert = async (msg) => {
  VueSimpleAlert.fire({
    title: `${msg}`,
    type: "error",
    timer: 3000
  }).then(() => {
    return true
  })
}

let warningAlert = async (msg) => {
  VueSimpleAlert.fire({
    title: `${msg}`,
    type: "info",
    timer: 3000
  }).then(() => {
    return true
  })
}

export { simpleAlert, successAlert, confirmAlert, errorAlert, warningAlert }
