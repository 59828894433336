<template>
  <v-list-item-avatar :class="{'mr-0 mr-lg-4': removeMargin}" :color="color" @click="goPage(link)" :size="size">
    <img :src="`${image}`" alt="avatar" v-if="image" />
  </v-list-item-avatar>
</template>

<script>
import { goPage } from "../utils/app"
export default {
  props: {
    color: {
      type: String,
      default: () => "grey"
    },
    image: {
      type: String,
      default: () => ""
    },
    link: {
      type: String,
      default: () => "#"
    },
    size: {
      type: Number,
      default: () => 40
    },
    removeMargin: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    goPage(path) {
      goPage(path)
    }
  }
}
</script>
