var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"card-post mb-4"},[_c('v-row',[_c('v-list-item',{staticClass:"profile-list d-flex"},[_c('Avatar',{attrs:{"image":_vm.details.img,"size":50,"color":"white"},nativeOn:{"click":function($event){return _vm.goPage(("/profile/timeline/" + (_vm.details.user_id)))}}}),_c('v-list-item-content',{staticClass:"text-start",on:{"click":function($event){return _vm.goPage(("/profile/timeline/" + (_vm.details.user_id)))}}},[_c('a',[_c('h5',{staticClass:"text-name"},[_vm._v(_vm._s(_vm.details.fullname))])]),_c('p',{staticClass:"text-date"},[_vm._v(_vm._s(_vm.details.create_at))]),(_vm.details.type == 'course')?_c('p',{staticClass:"text-privacy grey--text"},[_vm._v("("+_vm._s(_vm.privacy)+")")]):_vm._e()]),_vm._t("tag",[(_vm.details.type == 'course')?_c('TagSubject',{staticClass:"ml-auto"},[_vm._v(_vm._s(_vm.details.subject))]):_vm._e()])],2),_c('v-list-item',{staticClass:"text-start",attrs:{"ripple":false},on:{"click":function($event){return _vm.goPage(
          ("/" + (_vm.details.type == 'course' ? 'course/view' : 'post') + "/" + (_vm.details.post_id) + "/" + (_vm.$route.name == 'ViewCourseByGroup' || _vm.$route.name == 'TimelineGroup' ? ("group/" + (_vm.details.group_id)) : ''))
        )}}},[_vm._v(_vm._s(_vm.details.type == "course" ? _vm.details.caption : _vm.details.title))]),(_vm.details.type == 'course' || _vm.details.file_type == 'img')?_c('WrapperGallery',{attrs:{"gallery":_vm.details.files,"images":_vm.images}}):_vm._e(),(_vm.details.file_type == 'pdf')?_c('WrapperFiles',{attrs:{"files":_vm.details.files}}):_vm._e(),(!!_vm.details.link)?_c('WrapperYoutube',{attrs:{"link":_vm.details.link}}):_vm._e(),_c('v-flex',{attrs:{"xs12":""}}),(
        (_vm.$route.name !== 'timeline' && _vm.$route.name !== 'ViewCourse' && _vm.$route.name !== 'Feed') ||
        _vm.details.privacy !== 'group'
      )?_c('v-list-item',{staticClass:"flex-action justify-space-between my-4 border-verticle"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-chat-outline")]),_c('v-list-item-title',{staticClass:"text-start"},[_c('small',[_c('span',{staticClass:"text-comment"},[_vm._v(_vm._s(_vm.details.comment_count))])])]),(_vm.details.is_like)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.likePost()}}},[_vm._v("mdi-heart")]):_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.likePost()}}},[_vm._v("mdi-heart-outline")]),_c('v-list-item-title',{staticClass:"text-start",on:{"click":function($event){return _vm.likePost()}}},[_c('small',[_c('span',[_vm._v(_vm._s(_vm.details.emoticon_count))])])]),_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-share-variant")]),_c('v-list-item-title',{staticClass:"text-start"},[_c('small',[_c('span',[_vm._v("แชร์")])])]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.details.is_favorite ? 'primary' : ''},on:{"click":function($event){return _vm.favoritePost()}}},[_vm._v("mdi-bookmark")]),_c('v-list-item-title',{staticClass:"text-start",on:{"click":function($event){return _vm.favoritePost()}}},[_c('small',[_c('span',[_vm._v(_vm._s(_vm.details.favorite_count))])])]),(_vm.user.userId == _vm.details.user_id)?_c('v-menu',{staticClass:"d-flex",attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex grey--text",attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,false,990786185)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editPost()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-pencil")]),_c('a',[_c('p',[_vm._v("แก้ไข")])])],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.deletePost()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-trash-can")]),_c('a',[_c('p',{staticClass:"mb-0"},[_vm._v("ลบ")])])],1)],1)],1):_vm._e()],1):_vm._e(),(
        (_vm.$route.name !== 'timeline' &&
          _vm.$route.name !== 'ViewCourseByGroup' &&
          _vm.$route.name !== 'ViewCourse' &&
          _vm.$route.name !== 'Feed') ||
        _vm.details.privacy !== 'group'
      )?_c('v-list-item',[_c('Avatar',{attrs:{"image":_vm.user.img,"size":45,"color":"white","removeMargin":""},nativeOn:{"click":function($event){return _vm.goPage(("/profile/timeline/" + (_vm.user.userId)))}}}),_c('v-list-item-content',[_c('InputGroup',{ref:"inputComment",staticClass:"input-comment w-100",attrs:{"placeholder":"เขียนข้อความ","layout":"col-auto","color":"primary"},nativeOn:{"keydown":function($event){return _vm.sendComment($event, _vm.details.post_id)}}})],1)],1):_vm._e(),(
        (_vm.$route.name !== 'timeline' &&
          _vm.$route.name !== 'ViewCourseByGroup' &&
          _vm.$route.name !== 'ViewCourse' &&
          _vm.$route.name !== 'Feed') ||
        _vm.details.privacy !== 'group'
      )?_c('WrapperComment',{attrs:{"postId":_vm.details.post_id,"comments":_vm.details.comments,"userId":_vm.details.user_id},on:{"replySuccess":_vm.replySuccess}}):_vm._e(),(
        (_vm.$route.name == 'timeline' || _vm.$route.name == 'ViewCourse' || _vm.$route.name == 'Feed') &&
        _vm.details.privacy == 'group'
      )?_c('BtnSm',{staticClass:"btn-primary btn-rounded-pill mx-auto my-4",nativeOn:{"click":function($event){return _vm.join()}}},[(!_vm.details.ismember)?_c('v-icon',[_vm._v("mdi-plus")]):_vm._e(),_vm._v(" "+_vm._s(_vm.details.ismember ? "กดเข้ากลุ่ม" : "ขอเข้าร่วมกลุ่ม")+" ")],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }