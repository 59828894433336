<template>
  <ModalBaseFull v-model="isActive" :maxWidth="1000" :title="title">
    <template v-slot:body>
      <iframe
        width="100%"
        height="500"
        :src="`https://www.youtube.com/embed/${youtubeId}`"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </template>
  </ModalBaseFull>
</template>

<script>
import ModalBaseFull from "./ModalBaseFull"

export default {
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    title: {
      type: String,
      default: () => ""
    },
    link: {
      type: String
    },
    description: {
      type: String
    }
  },
  components: {
    ModalBaseFull
  },
  computed: {
    isActive: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    },
    youtubeId() {
      if (this.link && this.link.length) {
        const strWatch = "watch?v="
        const strDomain = "youtu.be/"

        if (this.link.includes(strWatch)) {
          let strSplit = this.link.split(strWatch)[1]
          return strSplit.substr(0, 11)
        } else if (this.link.includes(strDomain)) {
          let strSplit = this.link.split(strDomain)[1]
          return strSplit.substr(0, 11)
        }
      }
    }
  }
}
</script>
