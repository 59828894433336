import Vue from "vue"
import Vuetify from "vuetify/lib"
import "@mdi/font/css/materialdesignicons.css"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#f26522",
        secondary: "#9a6600",
        accent: "#8c9eff",
        error: "#fd331c"
      }
    }
  }
})
