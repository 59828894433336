<template>
  <v-select
    v-model="input"
    :class="`input-${inputStyle}`"
    :items="items"
    :item-text="itemText ? itemText : ''"
    :item-value="itemValue ? itemValue : ''"
    :return-object="returnObj"
    :label="label"
    :rules="validates"
    placeholder="เลือกกลุ่ม"
    hide-details="auto"
    solo
    outlined
    dense
  >
  <template slot="selection">
    <slot name="selection"></slot>
  </template>
    </v-select>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Array]
    },
    items: {
      type: Array
    },
    returnObj: {
      type: Boolean,
      default: () => false
    },
    itemText: {
      type: String
    },
    itemValue: {
      type: String
    },
    validates: {
      type: Array,
      default: () => []
    },
    label: {
      type: String
    },
    inputStyle: {
      type: String,
      default: () => "pill"
    }
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  }
}
</script>
