<template>
  <v-col :class="layout" class="text-start">
    <label class="ml-2" v-if="label">{{ label }}<span class="required" v-if="isRequired">*</span></label>
    <div class="d-flex justify-space-between">
      <SelectSm v-model="date" class="mr-1" :items="dateList" @change.native="calculateDate" :validates="validates" :inputStyle="inputStyle" />
      <SelectSm v-model="month" class="mr-1" :items="monthList" @change.native="calculateDate" :validates="validates"  :inputStyle="inputStyle"/>
      <SelectSm v-model="year" class="mr-1" :items="yearList" @change.native="calculateDate" :validates="validates"  :inputStyle="inputStyle"/>
    </div>
  </v-col>
</template>

<script>
import { getCurrent, month, yearPrev, generateArrayShift } from "../utils/app"
import SelectSm from "./SelectSm"
export default {
  data: () => ({
    dateList: generateArrayShift(),
    monthList: month,
    yearList: yearPrev()
  }),
  components: {
    SelectSm
  },
  props: {
    value: {
      type: String,
      default: () => getCurrent().date
    },
    layout: {
      type: String,
      default: () => "col-12"
    },
    label: {
      type: String,
      default: () => ""
    },
    isRequired: {
      type: Boolean,
      default: () => false
    },
    validates: {
      type: Array,
      default: () => []
    },
    inputStyle: {
      type: String,
      default: () => "pill"
    }
  },
  computed: {
    date: {
      get() {
        return Number(this.value.split("-")[2])
      },
      set(val) {
        const mergeDate = `${this.year}-${val.toString().padStart(2, "0")}-${val.toString().padStart(2, "0")}`
        this.mergeDate(mergeDate)
      }
    },
    month: {
      get() {
        return Number(this.value.split("-")[1])
      },
      set(val) {
        const mergeDate = `${this.year}-${val.toString().padStart(2, "0")}-${this.date.toString().padStart(2, "0")}`
        this.mergeDate(mergeDate)
      }
    },
    year: {
      get() {
        return Number(this.value.split("-")[0])
      },
      set(val) {
        const mergeDate = `${val}-${this.month.toString().padStart(2, "0")}-${this.date.toString().padStart(2, "0")}`
        this.mergeDate(mergeDate)
      }
    },
  },
  mounted() {
    this.calculateDate()
  },
  methods: {
    mergeDate(val) {
      this.$emit("input", val)
    },
    calculateDate() {
      const lastDay = new Date(Number(this.year), Number(this.month), 0).getDate()
      const date = Array.from(Array(lastDay + 1).keys())
      date.shift()
      this.dateList = date
    }
  },
  watch: {
    date() {
      this.calculateDate()
    },
    month() {
      this.calculateDate()
    },
    year() {
      this.calculateDate()
    }
  }
}
</script>

<style lang="scss" scoped>
.select-split-group {
  display: flex;
  justify-content: space-between;
}
</style>