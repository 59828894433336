<template>
  <div class="page-404-wrapper">
    <div class="special-text">404</div>
    <div class="description-text">Not Found Page</div>
    <v-btn @click="goHome()">Home Page</v-btn>
  </div>
</template>

<script>
export default {
  methods: {
    goHome() {
      this.$router.push("/")
    }
  }
}
</script>

<style lang="scss" scoped>
.page-404-wrapper {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .special-text {
    font-size: 120px;
    font-weight: 500;
  }

  .description-text {
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 16px;
  }
}
</style>
