<template>
  <v-col :class="layout" class="text-start py-2">
    <label class="ml-2" v-if="label">{{ label }}<span class="required" v-if="isRequired">*</span></label>
    <v-textarea
      v-model="input"
      class="input-md"
      :label="label"
      :placeholder="placeholder"
      :row="`${row}`"
      :outlined="!noOutlined"
      :color="color"
      hide-details="auto"
      required
      solo
      no-resize
    ></v-textarea>
  </v-col>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number]
    },
    label: {
      type: String,
      default: () => ""
    },
    placeholder: {
      type: String,
      default: () => ""
    },
    validates: {
      type: Array,
      default: () => []
    },
    layout: {
      type: String,
      default: () => "col-12"
    },
    isRequired: {
      type: Boolean,
      default: () => false
    },
    color: {
      type: String,
      default: () => "secondary"
    },
    row: {
      type: Number,
      default: () => 15
    },
    noOutlined: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  }
}
</script>
