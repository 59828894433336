import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import firebase from "firebase"
import "./utils/filters"
Vue.config.productionTip = false

/**
 * INITIALIZE FIREBASE ACCOUNT
 */

const firebaseConfig = {
  apiKey: "AIzaSyCiI5kYUTZ_HaCpI30oR9YChy8akTwUyqE",
  authDomain: "social-learning-platform.firebaseapp.com",
  databaseURL: "https://social-learning-platform.firebaseio.com",
  projectId: "social-learning-platform",
  storageBucket: "social-learning-platform.appspot.com",
  messagingSenderId: "35954666730",
  appId: "1:35954666730:web:9c274f0240af30754a3b93",
  measurementId: "G-WEHB107Y70"
};
// var firebaseConfig = {
//   apiKey: "AIzaSyCiI5kYUTZ_HaCpI30oR9YChy8akTwUyqE",
//   authDomain: "social-learning-platform.firebaseapp.com",
//   databaseURL: "https://social-learning-platform.firebaseio.com",
//   projectId: "social-learning-platform",
//   storageBucket: "social-learning-platform.appspot.com",
//   messagingSenderId: "35954666730"
//   // appId: "1:35954666730:web:9c274f0240af30754a3b93",
//   // measurementId: "G-WEHB107Y70"
// }
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app")
