<template>
  <v-dialog v-model="isActive" :max-width="`${maxWidth}px`" :fullscreen="$vuetify.breakpoint.xs">
    <v-card class="mb-4 text-end elevation-0 card-close d-sm-flex d-none">
      <v-btn icon class="ml-auto white--text transpaernt" :ripple="false" @click="isActive = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card>
    <v-card class="elevation-0 card-modal pa-4 justify-center text-center d-flex flex-column">
      <v-card-title class="text-center">
        <v-row class="justify-space-betweeen">
          <v-col class="text-center col-10 col-sm-12"
            ><h3>{{ title }}</h3>
          </v-col>
          <v-col class="col-2 d-sm-none text-end middle">
            <v-btn icon depressed>
              <v-icon class="grey--text" @click="isActive = false" size="26">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mb-auto">
        <slot name="body"></slot>
      </v-card-text>
      <v-card-text>
        <slot name="footer"></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    maxWidth: {
      type: Number,
      default: () => 1000
    },
    title: {
      type: String
    }
  },
  computed: {
    isActive: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  }
}
</script>
